import { useEffect, useState } from "react"
import { useFirebaseApp } from "reactfire"
import { initAppCheck } from "./app-check"
// import { Inline, SpinnerIcon } from "@cashbook/web-components"

interface AppCheckProviderProps {
  children: React.ReactNode
  recaptchaKey?: string
  fallback?: React.ReactNode
}

function AppCheckProvider({
  children,
  recaptchaKey,
  fallback = <div>Initializing security checks...</div>,
}: AppCheckProviderProps) {
  const firebaseApp = useFirebaseApp()
  const [isInitialized, setIsInitialized] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (recaptchaKey) {
      try {
        initAppCheck(firebaseApp, recaptchaKey)
        setIsInitialized(true)
      } catch (err) {
        setError(err as Error)
      }
    } else {
      setIsInitialized(true) // Skip initialization if no recaptcha key
    }
  }, [firebaseApp, recaptchaKey])

  if (error) {
    throw error // Let error boundary handle it
  }

  if (!isInitialized) {
    return <>{fallback}</>
  }

  return <>{children}</>
}

export default AppCheckProvider

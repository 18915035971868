import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  AppCheck,
  getToken,
} from "firebase/app-check"
import type { FirebaseApp } from "firebase/app"

let appCheckInstance: AppCheck | null = null

export function initAppCheck(
  firebaseApp: FirebaseApp,
  recaptchaEnterpriseKey: string
): AppCheck | null {
  try {
    appCheckInstance = initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaEnterpriseProvider(recaptchaEnterpriseKey),
      isTokenAutoRefreshEnabled: true,
    })
    return appCheckInstance
  } catch (error) {
    console.error("Failed to initialize App Check:", error)
    return null
  }
}

export async function getAppCheckToken(forceRefresh = false): Promise<string> {
  if (!appCheckInstance) {
    throw new Error("App Check not initialized")
  }

  try {
    const token = await getToken(appCheckInstance, forceRefresh)
    return token.token
  } catch (error) {
    console.error("Failed to get App Check token:", error)
    throw error
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PaymentStatus,
  PaymentTransactionTypes,
  PaymentsTransaction,
  transformDate,
  useCopyToCashbook,
  useGetAttachment,
  useTransactionDetails,
  useTransactionAttachment,
  DAYS_TILL_ADDITIONAL_NOTE_EDIT_ALLOWED,
  checkIfAllEntriesAreComplete,
} from "@cashbook/data-store/payments"
import {
  Alert,
  ArrowLeftIcon,
  ArrowRightIcon,
  AttachmentIcon,
  Box,
  Button,
  ButtonLink,
  CBButton,
  CancelFilledIcon,
  CancelIcon,
  CashbookWithNameIcon,
  CheckCircleSolidIcon,
  CheckIcon,
  Circle,
  ClockIcon,
  CopyIcon,
  CopyToClipboard,
  DataLoadingFallback,
  DisabledIcon,
  DocumentDownloadIcon,
  FormField,
  GearIcon,
  HourGlassEmptyIcon,
  IconProps,
  InformationCircleFilledIcon,
  InformationCircleIcon,
  InformationWarningIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  PencilOutlinedIcon,
  PlusIcon,
  SearchIcon,
  SharedEntriesIcon,
  SkeletonBox,
  SmallArrowHeadUpIcon,
  SpinnerIcon,
  Stack,
  Text,
  Time,
  Tooltip,
  TransactionDate,
  TrashIcon,
  checkFileSize,
  checkFileTypes,
  formikOnSubmitWithErrorHandling,
  useDownloadHTMLAsImage,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
  useEffect,
} from "react"
import { Amount } from "../support/Intl"
import {
  Avatar,
  GeneralErrorHandler,
  PAYMENTS_ICON_NAMES,
  PaymentsIcons,
} from "."
import { useBusiness } from "@cashbook/data-store/businesses"
import { toast } from "react-hot-toast"
import { Form, Formik } from "formik"
import * as Validator from "yup"
import PoweredByUpi from "./powered_by_upi.webp"
import {
  EntryAttachmentInfo,
  getAllMandatoryFields,
  getSectionWiseEntriesList,
  LogTransactionDataSchema,
  TBook,
  TBookCustomField,
  TBookEntryField,
  TTransaction,
  useBook,
  useBooksForBusinessId,
} from "@cashbook/data-store/books"
import { Timestamp } from "firebase/firestore"
import {
  formatDate,
  getDatesDifference,
  timeStampToDate,
} from "@cashbook/util-dates"
import config from "../config"
import { logInfo } from "@cashbook/util-logging"
import {
  categoryListStore,
  categorySettingsStore,
  userPaymentsProfileStore,
} from "@cashbook/data-store/storage"
import { Checkbox, Radio } from "../common"
import { pluralize } from "@cashbook/util-general"
import { useNavigate } from "react-router-dom"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import ChooseCategoryInModal from "./PaymentFields"
import {
  FormMultipleFilesField,
  LogTransactionForm,
  LogTransactionFormProps,
} from "../Transactions/Log"
import { SuspenseWithPerf } from "reactfire"
import PerformChangeFieldsInModal from "../Transactions/ChangeFields"

export function ViewTransactionAttachmentInModal({
  attachmentId,
  children,
}: {
  attachmentId?: number
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const { state: status, getAttachment } = useGetAttachment()

  function openModal() {
    state.open()
    if (attachmentId) {
      getAttachment(attachmentId)
    }
  }

  const imgSrc =
    status.status === "success"
      ? status?.data.url?.[0] || status?.data.thumbUrl[0]
      : ""

  return (
    <>
      {children({
        open: openModal,
      })}
      <Modal
        isDismissable
        isOpen={state.isOpen}
        onClose={state.close}
        title="Attachment Preview"
      >
        <ModalBody>
          <Box width="full" className="h-80">
            {status.status === "in_progress" ? (
              <SkeletonBox width="full" height="full" />
            ) : status.status === "success" && imgSrc.length ? (
              <Box paddingBottom="4">
                <img
                  className="rounded-md "
                  src={imgSrc}
                  alt="Transaction Attachment"
                />
              </Box>
            ) : (
              <Box paddingBottom="12">
                <GeneralErrorHandler
                  retryBtnText="Retry"
                  onRetry={() => {
                    if (attachmentId) {
                      getAttachment(attachmentId)
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        </ModalBody>
        {imgSrc.length ? (
          <ModalFooter>
            <ButtonLink
              size="lg"
              href={imgSrc}
              download="file.png"
              target="_blank"
              rel="noopner noreferrer"
              level="primary"
            >
              <DocumentDownloadIcon /> Download Attachment
            </ButtonLink>
          </ModalFooter>
        ) : null}
      </Modal>
    </>
  )
}

type InternalTransactionParty = {
  title: string
  subtext: string
  balance?: number
  icon: "bank" | "upiWallet" | "masterWallet" | "gradientCard"
}

export function TransactionDetailsInModal({
  transaction_type,
  children,
  ...props
}: React.ComponentProps<typeof TransactionDetails> & {
  transaction_type?: PaymentTransactionTypes
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})

  const transaction_scope: "internal" | "external" = useMemo(() => {
    return transaction_type === "B2B" ||
      transaction_type === "B2C" ||
      transaction_type === "C2B" ||
      transaction_type === "VIRTUAL_ACCOUNT_CREDIT"
      ? "internal"
      : "external"
  }, [transaction_type])

  const title: string = useMemo(() => {
    return transaction_type === "B2C"
      ? "Added To Wallet"
      : transaction_type === "B2B" ||
        transaction_type === "VIRTUAL_ACCOUNT_CREDIT"
      ? "Added to Master Wallet"
      : transaction_type === "C2B"
      ? "Withdrawn from Wallet"
      : "Transaction Details"
  }, [transaction_type])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isDismissable
        placement="right"
        isOpen={state.isOpen}
        onClose={state.close}
        title={title}
      >
        <TransactionDetails {...props} transaction_scope={transaction_scope} />
      </Modal>
    </>
  )
}

function TransactionDetails({
  memberId,
  businessId,
  isBillAttached,
  transactionId,
  npciTransactionId,
  transaction_scope,
  onAttachmentAdded,
  onAttachmentDeleted,
  ...externalProps
}: {
  memberId?: string
  transactionId: string
  npciTransactionId?: string
  isBillAttached?: boolean
  businessId: string
  transaction_scope?: "internal" | "external"
  onAttachmentDeleted?: () => void
  onAttachmentAdded?: () => void
}) {
  const {
    error,
    loading,
    attachments,
    transaction,
    transactionNote,
    transactionNoteUpdateApiLoading,
    retry,
    onUpdateTransactionNote,
  } = useTransactionDetails(
    businessId,
    transactionId,
    npciTransactionId,
    isBillAttached
  )

  return loading ? (
    <>
      <ModalBody>
        <Stack gap="6">
          <SkeletonBox width="full" height="full" className="h-48" />
          <SkeletonBox width="full" height="full" className="h-64" />
        </Stack>
      </ModalBody>
    </>
  ) : error?.message ? (
    <ModalBody>
      <Stack gap="6" height="full" alignItems="center" justifyContent="center">
        <Stack alignItems="center" justifyContent="center">
          <SharedEntriesIcon size="12" />
          <Stack gap="2" justifyContent="center" alignItems="center">
            <Stack justifyContent="center" alignItems="center">
              <Text fontSize="s1">Something Went Wrong!</Text>
              <Text fontSize="b3">
                Your data is absolutely safe and backed up
              </Text>
            </Stack>
            <Text fontSize="c2">{error?.message}</Text>
          </Stack>
        </Stack>
        <Button level="primary" onClick={retry}>
          Retry
        </Button>
      </Stack>
    </ModalBody>
  ) : transaction_scope === "internal" && transaction ? (
    <InternalTransactionDetails
      businessId={businessId}
      memberId={memberId}
      transaction={transaction}
    />
  ) : transaction_scope === "external" && transaction ? (
    <ExternalTransaction
      businessId={businessId}
      transaction={transaction}
      attachments={attachments}
      onAttachmentAdded={onAttachmentAdded}
      onAttachmentDeleted={onAttachmentDeleted}
      transactionNote={transactionNote}
      transactionNoteUpdateApiLoading={transactionNoteUpdateApiLoading}
      onUpdateTransactionNote={onUpdateTransactionNote}
      refetchTransactionDetails={retry}
      {...externalProps}
    />
  ) : null
}

function InternalTransactionDetails({
  memberId,
  businessId,
  transaction,
}: {
  memberId?: string
  businessId: string
  transaction: PaymentsTransaction
}) {
  const {
    amount,
    type,
    timestamp,
    created_at,
    transaction_type,
    balance,
    ext_party_fid,
    created_by,
    payer_meta,
    related,
  } = transaction
  const {
    business: { name },
    authTeamMemberDetails,
    getTeamMemberInfoForId,
  } = useBusiness(businessId)

  const from: InternalTransactionParty = useMemo(() => {
    const member = getTeamMemberInfoForId(ext_party_fid)
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
        return {
          title: payer_meta?.name ? payer_meta.name : "XXXX Bank XXXX",
          subtext: payer_meta?.id
            ? `A/c No - ${payer_meta?.id}`
            : "Bank Account",
          icon: "bank",
        }
      case "C2B":
        return {
          title:
            authTeamMemberDetails.id === member?.uid
              ? "Your Wallet"
              : member?.name || "",
          subtext: member?.phoneNumber || "",
          balance: Number(related?.balance) || 0,
          icon: "gradientCard",
        }
      default:
        return {
          title: name,
          subtext: "Master Wallet",
          balance: memberId ? Number(related?.balance || 0) : balance || 0,
          icon: "masterWallet",
        }
    }
  }, [
    getTeamMemberInfoForId,
    ext_party_fid,
    transaction_type,
    payer_meta?.name,
    payer_meta?.id,
    authTeamMemberDetails.id,
    related?.balance,
    name,
    memberId,
    balance,
  ])

  const to: InternalTransactionParty = useMemo(() => {
    if (transaction_type === "B2C") {
      const member = getTeamMemberInfoForId(memberId || ext_party_fid)
      return {
        title:
          member?.id === authTeamMemberDetails.id
            ? "Your Wallet"
            : member?.name
            ? `${member.name}'s Wallet`
            : "",
        subtext: member?.phoneNumber || "",
        balance:
          memberId && balance ? balance : related ? Number(related.balance) : 0,
        icon: "gradientCard",
      }
    }
    return {
      title: name,
      subtext: "Master Wallet",
      balance: balance || 0,
      icon: "masterWallet",
    }
  }, [
    authTeamMemberDetails.id,
    balance,
    ext_party_fid,
    getTeamMemberInfoForId,
    name,
    related,
    transaction_type,
    memberId,
  ])

  const transactionBy = useMemo(() => {
    const member = getTeamMemberInfoForId(created_by)
    if (
      transaction_type !== "B2B" &&
      transaction_type !== "VIRTUAL_ACCOUNT_CREDIT"
    ) {
      return {
        id: member?.id || "",
        name:
          member?.id === authTeamMemberDetails.id ? "You" : member?.name || "",
        phone: member?.phoneNumber || "",
      }
    }
  }, [
    getTeamMemberInfoForId,
    created_by,
    transaction_type,
    authTeamMemberDetails.id,
  ])

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Box
            rounded="md"
            borderWidth="1"
            borderTopWidth="4"
            borderColor="borderOutline"
            borderTopColor={
              type === "CREDIT" ? "surfaceCashIn" : "surfaceCashOut"
            }
          >
            <Inline
              borderBottomWidth="1"
              borderColor="borderOutline"
              paddingY="5"
              paddingX="6"
              alignItems="center"
              justifyContent="between"
            >
              <Amount
                amount={Number(amount)}
                currency="inr"
                fontSize="h2"
                type={type === "DEBIT" ? "cash-out" : "cash-in"}
              />
              <TransactionDate
                fontSize="b3"
                showFullDate
                format="dd MMM yyyy, hh:mm a"
                timeStamp={transformDate(timestamp || created_at)}
              />
            </Inline>
            <Stack
              padding="6"
              gap={from.balance ? "10" : "16"}
              position="relative"
            >
              <Inline gap="6">
                <PaymentsIcons size="10" color="iconPrimary" name={from.icon} />
                <Stack gap="2">
                  <Text fontSize="s3" className="line-clamp-2">
                    {from.title}
                  </Text>
                  <Inline gap="4">
                    <Text fontSize="b3" color="textMedium">
                      {from.subtext}
                    </Text>
                  </Inline>

                  {from.balance ? (
                    <Inline gap="2" fontSize="b3" color="textMedium">
                      <Text>Closing Balance: </Text>
                      <Amount currency="inr" amount={from.balance} />
                    </Inline>
                  ) : null}
                </Stack>
              </Inline>
              <Box
                width="10"
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="14"
              >
                <svg
                  width="14"
                  height="34"
                  viewBox="0 0 14 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.00047 0H6.00047V24.1851H0.0722656L7.00047 34L13.9287 24.1851H8.00047V0Z"
                    fill="#B6C1EE"
                  />
                </svg>
              </Box>
              <Inline gap="6">
                <PaymentsIcons size="10" name={to.icon} />
                <Stack gap="2">
                  <Text fontSize="s3" className="line-clamp-2">
                    {to.title}
                  </Text>
                  <Inline gap="4">
                    <Text fontSize="b3" color="textMedium">
                      {to.subtext}
                    </Text>
                  </Inline>
                  {to.balance ? (
                    <Inline gap="2" fontSize="b3" color="textMedium">
                      <Text>Closing Balance: </Text>
                      <Amount currency="inr" amount={to.balance} />
                    </Inline>
                  ) : null}
                </Stack>
              </Inline>
            </Stack>
          </Box>
          <Box borderWidth="1" rounded="md" borderColor="borderOutline">
            <Box
              paddingY="4"
              paddingX="6"
              borderBottomWidth="1"
              borderColor="borderOutline"
            >
              <Text color="textMedium" fontSize="s3">
                Other Details
              </Text>
            </Box>
            <Stack gap="6" padding="6">
              {transactionBy?.name ? (
                <Stack gap="4">
                  <Text fontSize="b3" color="textMedium">
                    Transaction by
                  </Text>
                  <Inline gap="4" alignItems="center">
                    <Avatar
                      id={transactionBy.id}
                      name={transactionBy.name}
                      size="12"
                      fontSize="h3"
                    />
                    <Stack gap="2">
                      <Text fontSize="s3" className="line-clamp-1">
                        {transactionBy.name}
                      </Text>
                      <Text fontSize="b3" color="textMedium">
                        {transactionBy.phone}
                      </Text>
                    </Stack>
                  </Inline>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </ModalBody>
    </>
  )
}

type TransactionIconName = "upi" | "card" | "bank"

const addAttachmentSchema = Validator.object().shape({
  transaction_file: Validator.mixed()
    .nullable()
    .test(
      "type-is-correct",
      "Only image files (png/jpeg/jpg) are supported.",
      checkFileTypes(["image/png", "image/jpeg", "image/jpg"])
    )
    .test(
      "size-not-big",
      "Please select the image that is less then 5 MB in size",
      checkFileSize(5000)
    ),
})

type OTHER_DETAILS_OPTION_TYPE = {
  id: "otherDetails" | "paymentStatus"
  title: string
}

type PartiesInvolvedType = {
  id: string
  name: string
  title?: string
  subtext: string
  icon: TransactionIconName
  partyType?: string
}
function ExternalTransaction({
  transaction,
  attachments,
  businessId,
  transactionNote,
  transactionNoteUpdateApiLoading,
  onAttachmentAdded,
  onAttachmentDeleted,
  onUpdateTransactionNote,
  refetchTransactionDetails,
}: {
  businessId: string
  attachments?: { [id: string]: EntryAttachmentInfo }
  transaction: PaymentsTransaction
  onAttachmentDeleted?: () => void
  onAttachmentAdded?: () => void
  transactionNote: string | null
  transactionNoteUpdateApiLoading: boolean
  onUpdateTransactionNote: (note: string) => void
  refetchTransactionDetails: () => void
}) {
  const {
    id,
    amount,
    type,
    timestamp,
    created_at,
    party,
    description,
    cust_ref,
    status,
    created_by,
    user_id,
    copied_to_books,
    npci_txn_id,
  } = transaction

  const { business, authTeamMemberDetails, getTeamMemberInfoForId } =
    useBusiness(businessId)
  const { getBookNamesById } = useBooksForBusinessId(businessId)
  const { deleteAttachment, addAttachment } = useTransactionAttachment()

  const [activeDetails, setActiveDetails] = useState<
    "otherDetails" | "paymentStatus"
  >("otherDetails")
  const categorySettingsMappedWithBusinessId = useSyncExternalStore(
    categorySettingsStore.subscribe,
    categorySettingsStore.getCategorySettings
  )
  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )
  const categoriesList = categoriesListMappedWithBusinessId[businessId]
  const selectedCategory = useMemo(() => {
    const selectedCategory = categoriesList.find((category) => {
      return (
        transaction?.metadata?.category_id &&
        (category.id === transaction?.metadata?.category_id ||
          category.default_id === transaction?.metadata?.category_id)
      )
    })
    return selectedCategory
  }, [categoriesList, transaction?.metadata?.category_id])
  const isCategoryDisabled =
    categorySettingsMappedWithBusinessId[businessId]?.disabled

  const transactionParty: PartiesInvolvedType = useMemo(() => {
    return {
      id: party?.id || "",
      name: party?.name || "",
      subtext: party?.address || "",
      icon: "upi",
      partyType: party?.entity_type,
    }
  }, [party])

  const authUserProfile = userPaymentsProfileStore.getUserProfile()

  const isCurrentUserPayeeOrPayer: boolean = useMemo(() => {
    if (authUserProfile && authUserProfile?.entityId) {
      return Boolean(authUserProfile.entityId === transaction.entity_id)
    } else if (
      authTeamMemberDetails?.id === user_id ||
      authTeamMemberDetails?.id === created_by
    ) {
      return true
    }
    return false
  }, [
    authTeamMemberDetails?.id,
    authUserProfile,
    created_by,
    transaction.entity_id,
    user_id,
  ])

  const transactionMember: PartiesInvolvedType = useMemo(() => {
    if (isCurrentUserPayeeOrPayer) {
      return {
        id: authTeamMemberDetails?.id,
        name: authTeamMemberDetails.name || "",
        subtext: authUserProfile?.upi?.address || "",
        title: "Your Wallet",
        icon: transactionParty.icon,
      }
    }
    const member = getTeamMemberInfoForId(user_id || "")
    return {
      id: member?.id || "",
      name: member?.name || "",
      subtext: "",
      title: `${member?.name}'s Wallet`,
      icon: transactionParty.icon,
    }
  }, [
    isCurrentUserPayeeOrPayer,
    getTeamMemberInfoForId,
    user_id,
    transactionParty.icon,
    authTeamMemberDetails.id,
    authTeamMemberDetails.name,
    authUserProfile?.upi?.address,
  ])

  const showPaymentStatus: boolean = useMemo(() => {
    if (
      transaction.status === "SUCCESS" ||
      transaction.status === "FAILURE" ||
      transaction.status === "PENDING" ||
      transaction.status === "DEEMED" ||
      transaction.status === "REFUNDED" ||
      transaction.status === "REVERSED"
    ) {
      if (transactionParty.icon === "upi" && id && type === "DEBIT") {
        return true
      }
    }
    return false
  }, [id, transaction.status, transactionParty.icon, type])

  const OPTIONS_FOR_MORE_DETAILS: Array<OTHER_DETAILS_OPTION_TYPE> =
    useMemo(() => {
      const optionForOtherDetails: OTHER_DETAILS_OPTION_TYPE[] = [
        { id: "otherDetails", title: "Other Details" },
      ]
      if (showPaymentStatus) {
        optionForOtherDetails.push({
          id: "paymentStatus",
          title: "Payment Status",
        })
      }
      return optionForOtherDetails
    }, [showPaymentStatus])

  const copiedTo: string | undefined = useMemo(() => {
    if (!copied_to_books) {
      return
    }
    const bookIds = Object.keys(copied_to_books)
    const bookByIds = getBookNamesById()
    let copiedToBooks = ""
    bookIds.forEach((id, i) => {
      if (bookByIds[id]) {
        copiedToBooks =
          copiedToBooks +
          ` ${bookByIds[id]}${i === bookIds.length - 1 ? "" : ","} `
      }
    })
    return copiedToBooks
  }, [copied_to_books, getBookNamesById])

  const canEditTransactionNote = useMemo(() => {
    if (authTeamMemberDetails?.id === user_id && created_at) {
      const daysPassed = getDatesDifference(
        new Date(),
        new Date(created_at.toString())
      )
      if (daysPassed > DAYS_TILL_ADDITIONAL_NOTE_EDIT_ALLOWED) {
        return false
      }
      return true
    }
    return false
  }, [authTeamMemberDetails?.id, user_id, created_at])

  const { download, status: downloadStatus } = useDownloadHTMLAsImage()

  const toAndFrom = useMemo(() => {
    if (type === "CREDIT") {
      return {
        to: transactionMember,
        from: transactionParty,
      }
    } else {
      return {
        to: transactionParty,
        from: transactionMember,
      }
    }
  }, [transactionMember, transactionParty, type])

  const otherDetailsForReceipt = useMemo(() => {
    const details: { [key: string]: string } = {}
    if (description?.length) {
      details["Remark/Note"] = description
    }
    if (business.name) {
      details["Business Name"] = business.name
    }
    if (npci_txn_id?.length) {
      details["Transaction ID"] = npci_txn_id || ""
    }
    if (cust_ref) {
      details["UPI Reference Number"] = cust_ref
    }
    return details
  }, [business.name, cust_ref, description, npci_txn_id])

  const receiptHtmlRef = useRef<HTMLDivElement>(null)

  const downloadReceipt = useCallback(async () => {
    if (!receiptHtmlRef.current) return
    const fileName = `Invoice ${id} ${formatDate(new Date(), "dd-MM-yyyy")}@${
      config.appTitle
    }`
    try {
      receiptHtmlRef.current.classList.replace("opacity-0", "opacity-1")
      download(receiptHtmlRef.current, fileName).then(() => {
        receiptHtmlRef.current?.classList.replace("opacity-0", "opacity-1")
      })
    } catch (e) {
      const error = e as Error
      toast.error(error.message || "Something Went Wrong!")
      logInfo(
        `Error generating transaction receipt "${error?.message || "Unknown"}"`
      )
    }
  }, [download, id])

  const topBorderColor: React.ComponentProps<typeof Box>["borderTopColor"] =
    useMemo(() => {
      switch (status) {
        case "SUCCESS":
        case "REFUNDED":
          return "borderSuccess"
        case "FAILURE":
        case "REVERSED":
          return "borderError"
        case "EXPIRED":
          return "iconMedium"
        default:
          return "borderWarning"
      }
    }, [status])

  return (
    <>
      <ModalBody>
        <Box position="relative">
          <Box backgroundColor="backgroundLight1">
            <Stack gap="6">
              <Box
                rounded="md"
                borderWidth="1"
                borderTopWidth="4"
                borderTopColor={topBorderColor}
                borderColor="borderOutline"
              >
                <Stack
                  paddingY="5"
                  paddingX="6"
                  gap="4"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                >
                  {status !== "SUCCESS" &&
                  status !== "REFUNDED" &&
                  status !== "EXPIRED" ? (
                    <PaymentStatusBanner status={status} />
                  ) : null}
                  <Stack gap="2">
                    <Inline justifyContent="between" alignItems="center">
                      <Inline alignItems="center" gap="2">
                        <Amount
                          fontSize="h2"
                          currency="inr"
                          amount={Number(amount || 0)}
                        />
                        {status === "PENDING" || status === "DEEMED" ? (
                          <ClockIcon color="iconWarning" />
                        ) : status === "EXPIRED" ? (
                          <HourGlassEmptyIcon color="iconMedium" />
                        ) : status === "FAILURE" || status === "REVERSED" ? (
                          <CancelFilledIcon color="iconError" />
                        ) : (
                          <SmallArrowHeadUpIcon
                            className={type === "DEBIT" ? "" : "rotate-180"}
                            color={
                              type === "DEBIT" ? "iconCashOut" : "iconCashIn"
                            }
                          />
                        )}
                      </Inline>
                      <TransactionDate
                        fontSize="b3"
                        showFullDate
                        format="dd MMM yyyy, hh:mm a"
                        timeStamp={transformDate(timestamp || created_at)}
                      />
                    </Inline>
                    <Box display="flex" justifyContent="end" alignItems="end">
                      <PaymentStatusTag
                        type={type}
                        status={status}
                        transactionType={transaction.transaction_type}
                      />
                    </Box>
                  </Stack>
                </Stack>
                {transactionParty.id && transactionMember.id ? (
                  <Stack gap="6" padding="6">
                    <Stack gap="2">
                      <Text fontSize="b3">
                        {type === "DEBIT"
                          ? status === "SUCCESS"
                            ? "Paid to"
                            : "To"
                          : "From"}
                      </Text>
                      <Inline gap="3" alignItems="center">
                        <Avatar
                          size="10"
                          id={transactionParty.id}
                          name={transactionParty.name}
                          fontSize="s2"
                          iconSize="6"
                          type={
                            party?.entity_type === "ENTITY"
                              ? "merchant"
                              : "individual"
                          }
                        />
                        <Stack gap="2">
                          <Text fontSize="s4">{transactionParty.name}</Text>
                          {transactionParty.subtext.length &&
                          transactionParty.icon ? (
                            <Inline gap="1" alignItems="center">
                              {transaction.party?.entity_type !== "ENTITY" ? (
                                <PaymentsIcons
                                  size="3"
                                  color="iconMedium"
                                  name={transactionParty.icon}
                                />
                              ) : null}
                              <Text fontSize="c2" color="textMedium">
                                {transactionParty.subtext}
                              </Text>
                            </Inline>
                          ) : null}
                        </Stack>
                      </Inline>
                    </Stack>
                    <Inline
                      rounded="md"
                      padding="4"
                      gap="3"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Text fontSize="c2" color="textMedium">
                        {type === "DEBIT" ? "From" : "To"}
                      </Text>
                      <Stack gap="2">
                        <Text>{transactionMember.title}</Text>
                        {transactionMember.icon &&
                        transactionMember.subtext?.length ? (
                          <Inline gap="1" alignItems="center">
                            <PaymentsIcons
                              size="3"
                              color="iconMedium"
                              name={transactionMember.icon}
                            />
                            <Text fontSize="c2" color="textMedium">
                              {transactionMember.subtext}
                            </Text>
                          </Inline>
                        ) : null}
                      </Stack>
                    </Inline>
                  </Stack>
                ) : null}
              </Box>
              <Box borderWidth="1" borderColor="borderOutline" rounded="md">
                <Inline
                  as="ul"
                  gap="2"
                  paddingTop="2"
                  paddingX="6"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                >
                  {OPTIONS_FOR_MORE_DETAILS.map((option) => (
                    <Box
                      key={option.id}
                      padding="3"
                      cursor="pointer"
                      onClick={() => {
                        setActiveDetails(option.id)
                      }}
                      borderBottomWidth={
                        activeDetails === option.id ? "2" : undefined
                      }
                      borderColor="borderPrimary"
                    >
                      <Text
                        fontSize="s4"
                        color={
                          activeDetails === option.id
                            ? "textPrimary"
                            : "textMedium"
                        }
                      >
                        {option.title}
                      </Text>
                    </Box>
                  ))}
                </Inline>
                <Box padding="6" backgroundColor="backgroundLight2">
                  {activeDetails === "otherDetails" ? (
                    <Stack gap="6">
                      {!isCategoryDisabled ? (
                        <Inline justifyContent="between" alignItems="center">
                          <Stack gap="2">
                            <Inline gap="2" alignItems="center">
                              <Text fontSize="c2" color="textMedium">
                                Category
                              </Text>
                              <TransactionFieldDetailsInDialog
                                title="Additional Note"
                                points={[
                                  "Additional note is for adding any additional details about the transaction",
                                  "This can be edited up to 30 days from date of payment",
                                ]}
                              >
                                {({ open }) => (
                                  <InformationCircleIcon
                                    size="4"
                                    color="iconPrimary"
                                    cursor="pointer"
                                    onClick={open}
                                  />
                                )}
                              </TransactionFieldDetailsInDialog>
                            </Inline>
                            {selectedCategory?.name ? (
                              <Text fontSize="b3">
                                {selectedCategory?.name}
                              </Text>
                            ) : (
                              <ChooseCategoryInModal
                                source="transactionDetails"
                                businessId={businessId}
                                npciTranscationIds={[transaction.npci_txn_id]}
                                onSuccess={() => {
                                  refetchTransactionDetails()
                                }}
                              >
                                {({ handleAction }) => (
                                  <Box cursor="pointer">
                                    <Button
                                      type="button"
                                      onClick={() => handleAction("copy")}
                                    >
                                      Add Category
                                    </Button>
                                  </Box>
                                )}
                              </ChooseCategoryInModal>
                            )}
                          </Stack>
                          {selectedCategory?.name ? (
                            <Inline gap="2">
                              <ChooseCategoryInModal
                                source="transactionDetails"
                                businessId={businessId}
                                npciTranscationIds={[transaction.npci_txn_id]}
                                onSuccess={() => {
                                  refetchTransactionDetails()
                                }}
                                selectedField={selectedCategory}
                              >
                                {({ handleAction }) => (
                                  <PencilOutlinedIcon
                                    cursor="pointer"
                                    color={
                                      transactionNoteUpdateApiLoading
                                        ? "iconLow"
                                        : "iconPrimary"
                                    }
                                    onClick={() => {
                                      handleAction("copy")
                                    }}
                                  />
                                )}
                              </ChooseCategoryInModal>
                            </Inline>
                          ) : null}
                        </Inline>
                      ) : null}
                      {status === "SUCCESS" ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            Attachment
                          </Text>
                          <Formik
                            initialValues={{
                              bill_file: undefined as
                                | {
                                    file: File
                                    fileName: string
                                    fileType: string
                                  }
                                | undefined,
                              attachments: attachments,
                              operation: undefined,
                              attachmentId: undefined,
                            }}
                            onSubmit={formikOnSubmitWithErrorHandling(
                              async (values, actions) => {
                                if (
                                  values.operation === "delete" &&
                                  values.attachmentId
                                ) {
                                  await deleteAttachment(
                                    Number(values.attachmentId)
                                  )
                                  onAttachmentDeleted?.()
                                  const deletedAttachments = {
                                    ...values.attachments,
                                  }
                                  delete deletedAttachments?.[
                                    values.attachmentId
                                  ]
                                  actions.setFieldValue(
                                    "attachments",
                                    deletedAttachments
                                  )
                                  return
                                }
                                if (values.bill_file) {
                                  const { data } = await addAttachment(
                                    values.bill_file.file,
                                    npci_txn_id
                                  )
                                  if (data && data.id) {
                                    onAttachmentAdded?.()
                                    const updatedAttachments = {
                                      ...values.attachments,
                                    }
                                    updatedAttachments[data.id] = {
                                      url: data.url || "",
                                      thumbUrl: data.thumbUrl || "",
                                      fileName: "",
                                      mimeType: data.mime_type || "image/png",
                                      id: data.id || "",
                                    }
                                    actions.setFieldValue(
                                      "attachments",
                                      updatedAttachments
                                    )
                                  }
                                }
                              }
                            )}
                            validationSchema={addAttachmentSchema}
                          >
                            {({
                              values,
                              isSubmitting,
                              setFieldValue,
                              submitForm,
                            }) => (
                              <Form noValidate>
                                <Box className="max-w-fit" position="relative">
                                  <FormMultipleFilesField
                                    name="bill_file"
                                    id="bill_file"
                                    from="payments"
                                    defaultValues={values.attachments}
                                    loading={isSubmitting}
                                    onSelectionEnd={() => {
                                      setFieldValue("operation", "add")
                                      submitForm()
                                    }}
                                    onRemove={(attachmentId: string) => {
                                      setFieldValue(
                                        "attachmentId",
                                        attachmentId
                                      )
                                      setFieldValue("operation", "delete")
                                      submitForm()
                                    }}
                                  />
                                </Box>
                              </Form>
                            )}
                          </Formik>
                        </Stack>
                      ) : null}

                      {description ? (
                        <Stack gap="2">
                          <Inline gap="2" alignItems="center">
                            <Text fontSize="c2" color="textMedium">
                              UPI Remark
                            </Text>
                            <TransactionFieldDetailsInDialog
                              title="UPI Remark"
                              points={[
                                "UPI remark is the note added when making a payment",
                                "You cannot edit this remark because it has typically already been shared with the person to whom you made the payment",
                              ]}
                            >
                              {({ open }) => (
                                <InformationCircleIcon
                                  size="4"
                                  color="iconPrimary"
                                  cursor="pointer"
                                  onClick={open}
                                />
                              )}
                            </TransactionFieldDetailsInDialog>
                          </Inline>
                          <Text fontSize="b3">{description}</Text>
                        </Stack>
                      ) : null}

                      <Inline justifyContent="between" alignItems="center">
                        <Stack gap="2">
                          <Inline gap="2" alignItems="center">
                            <Text fontSize="c2" color="textMedium">
                              Additional Note
                            </Text>
                            <TransactionFieldDetailsInDialog
                              title="Additional Note"
                              points={[
                                "Additional note is for adding any additional details about the transaction",
                                "This can be edited up to 30 days from date of payment",
                              ]}
                            >
                              {({ open }) => (
                                <InformationCircleIcon
                                  size="4"
                                  color="iconPrimary"
                                  cursor="pointer"
                                  onClick={open}
                                />
                              )}
                            </TransactionFieldDetailsInDialog>
                          </Inline>
                          {transactionNote ? (
                            <Text fontSize="b3">{transactionNote}</Text>
                          ) : canEditTransactionNote ? (
                            <EditTransactionNoteInDialog
                              defaultValue={null}
                              onUpdateTransactionNote={onUpdateTransactionNote}
                            >
                              {({ open }) => (
                                <Box cursor="pointer">
                                  <Button
                                    type="button"
                                    disabled={transactionNoteUpdateApiLoading}
                                    onClick={open}
                                  >
                                    {transactionNoteUpdateApiLoading
                                      ? "Adding..."
                                      : "Add Additional Note"}
                                  </Button>
                                </Box>
                              )}
                            </EditTransactionNoteInDialog>
                          ) : (
                            <Text fontSize="b3" color="textMedium">
                              {"-"}
                            </Text>
                          )}
                        </Stack>
                        {transactionNote && canEditTransactionNote ? (
                          <Inline gap="2">
                            <EditTransactionNoteInDialog
                              defaultValue={transactionNote}
                              onUpdateTransactionNote={onUpdateTransactionNote}
                            >
                              {({ open }) => (
                                <PencilOutlinedIcon
                                  cursor="pointer"
                                  color={
                                    transactionNoteUpdateApiLoading
                                      ? "iconLow"
                                      : "iconPrimary"
                                  }
                                  onClick={() => {
                                    if (!transactionNoteUpdateApiLoading) {
                                      open()
                                    }
                                  }}
                                />
                              )}
                            </EditTransactionNoteInDialog>
                            <TrashIcon
                              cursor="pointer"
                              color={
                                transactionNoteUpdateApiLoading
                                  ? "iconLow"
                                  : "iconError"
                              }
                              onClick={() => {
                                if (!transactionNoteUpdateApiLoading) {
                                  onUpdateTransactionNote("")
                                }
                              }}
                            />
                          </Inline>
                        ) : null}
                      </Inline>

                      {npci_txn_id?.length ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            Transaction ID
                          </Text>
                          <Inline justifyContent="between" alignItems="center">
                            <Text fontSize="b3">{npci_txn_id}</Text>
                            <CopyToClipboard>
                              {({ copied, copy }) => {
                                return copied ? (
                                  <CheckIcon color="iconSuccess" />
                                ) : (
                                  <Tooltip
                                    event="onHover"
                                    content="Copy to clipboard"
                                  >
                                    <CopyIcon
                                      cursor="pointer"
                                      onClick={() => {
                                        copy(npci_txn_id || "")
                                        toast.success(
                                          "Transaction ID copied to clipboard"
                                        )
                                      }}
                                      color="textMedium"
                                    />
                                  </Tooltip>
                                )
                              }}
                            </CopyToClipboard>
                          </Inline>
                        </Stack>
                      ) : null}

                      {cust_ref?.length ? (
                        <Stack gap="2">
                          <Text fontSize="c2" color="textMedium">
                            UPI Reference Number
                          </Text>
                          <Inline justifyContent="between" alignItems="center">
                            <Text fontSize="b3">{cust_ref}</Text>
                            <CopyToClipboard>
                              {({ copied, copy }) => {
                                return copied ? (
                                  <CheckIcon color="iconSuccess" />
                                ) : (
                                  <Tooltip
                                    event="onHover"
                                    content="Copy to clipboard"
                                  >
                                    <CopyIcon
                                      cursor="pointer"
                                      onClick={() => {
                                        copy(cust_ref)
                                        toast.success(
                                          "UPI Reference number copied to clipboard"
                                        )
                                      }}
                                      color="textMedium"
                                    />
                                  </Tooltip>
                                )
                              }}
                            </CopyToClipboard>
                          </Inline>
                        </Stack>
                      ) : null}
                    </Stack>
                  ) : (
                    <PaymentJourney
                      status={status}
                      isReversed={status === "REVERSED"}
                    />
                  )}
                </Box>
              </Box>
              {copiedTo?.length ? (
                <Box borderWidth="1" borderColor="borderOutline" rounded="md">
                  <Box
                    paddingY="3"
                    paddingX="6"
                    borderBottomWidth="1"
                    borderColor="borderOutline"
                  >
                    <Text fontSize="s4" color="textMedium">
                      Copied To
                    </Text>
                  </Box>
                  <Box padding="6" backgroundColor="backgroundLight2">
                    <Text>{copiedTo}</Text>
                  </Box>
                </Box>
              ) : null}
            </Stack>
            {transactionParty.icon === "upi" ? (
              <Stack paddingY="12" alignItems="center" justifyContent="center">
                <Box width="24">
                  <img src={PoweredByUpi} alt="Powered By UPI" />
                </Box>
              </Stack>
            ) : null}
          </Box>
          {status === "SUCCESS" ||
          status === "FAILURE" ||
          status === "REVERSED" ||
          status === "REFUNDED" ? (
            <Box
              position="absolute"
              className="-z-[1]"
              width="full"
              top="0"
              opacity="0"
            >
              <Box ref={receiptHtmlRef}>
                <TransactionReceiptWithDetails
                  type={type}
                  status={status}
                  to={toAndFrom.to}
                  from={toAndFrom.from}
                  amount={Number(amount || 0)}
                  timestamp={transformDate(timestamp || created_at)}
                  otherDetails={otherDetailsForReceipt}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </ModalBody>
      {(status === "SUCCESS" ||
        status === "FAILURE" ||
        status === "REVERSED" ||
        status === "REFUNDED") && (
        <ModalFooter>
          {(status === "SUCCESS" || status === "REFUNDED") && (
            <CopyTransactionsToCashbook
              businessId={businessId}
              transactions={[transaction]}
            >
              {({ open }) => (
                <CBButton
                  onClick={() => {
                    open()
                    trackEvent(
                      TrackingEvents.PAYMENTS_COPY_TO_CASHBOOK_INITIATED,
                      {
                        from: "transactionDetails",
                      }
                    )
                  }}
                  level="primary"
                  size="lg"
                  iconPlacement="left"
                >
                  <CopyIcon /> Copy To Cashbook
                </CBButton>
              )}
            </CopyTransactionsToCashbook>
          )}
          <Button
            size="lg"
            onClick={downloadReceipt}
            disabled={downloadStatus === "in_progress"}
          >
            <Box>
              {downloadStatus === "in_progress" ? (
                <SpinnerIcon />
              ) : (
                <DocumentDownloadIcon />
              )}
            </Box>
            Download Receipt
          </Button>
        </ModalFooter>
      )}
    </>
  )
}

function EditTransactionNoteInDialog({
  children,
  ...props
}: React.ComponentProps<typeof EditTransactionNote> & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        size="sm"
        onClose={state.close}
        title={`${props.defaultValue ? "Update" : "Add"} Additional Note`}
      >
        <EditTransactionNote {...props} onClose={state.close} />
      </Modal>
    </>
  )
}

function TransactionFieldDetailsInDialog({
  title,
  points,
  children,
}: {
  title: string
  points: string[]
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        size="sm"
        onClose={state.close}
        title={title || "Details"}
      >
        <ModalBody>
          <Stack gap="6" marginTop="2" marginBottom="4">
            {points.map((point) => (
              <Inline gap="4" alignItems="center">
                <Box width="2" height="2" rounded="full" bgColor="iconLow" />
                <Text>{point}</Text>
              </Inline>
            ))}
          </Stack>
        </ModalBody>
      </Modal>
    </>
  )
}

function EditTransactionNote({
  defaultValue,
  onUpdateTransactionNote,
  onClose,
}: {
  defaultValue: string | null
  onUpdateTransactionNote: (note: string) => void
  onClose?: () => void
}) {
  return (
    <Formik
      initialValues={{ note: defaultValue || "" }}
      validationSchema={Validator.object().shape({
        note: Validator.string()
          .required(`Please enter a valid note`)
          .max(50, "Note must be below 50 characters"),
      })}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        onUpdateTransactionNote(values.note)
        onClose?.()
      })}
    >
      {({ errors }) => (
        <Form noValidate>
          <ModalBody>
            <FormField
              type="text"
              defaultValue={defaultValue || ""}
              name="note"
              label={`Note`}
              placeholder={"e.g. Pay for Food or Grocery"}
              required
              autoFocus
              hideAsterisk
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={Boolean(errors?.note)} size="lg">
              {"Save"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

type StepInPaymentJourney = {
  title: string
  icon: PAYMENTS_ICON_NAMES
  iconColor: React.ComponentProps<typeof PaymentsIcons>["color"]
}

function getPaymentStatusInSteps(
  isReversed: boolean,
  status: PaymentStatus
): StepInPaymentJourney[] {
  const steps: StepInPaymentJourney[] = [
    {
      title: "Wallet PIN validated and payment started",
      icon: "checkCircle",
      iconColor: "iconSuccess",
    },
  ]
  if (
    status === "SUCCESS" ||
    status === "FAILURE" ||
    status === "REVERSED" ||
    status === "DEEMED"
  ) {
    steps.push({
      title: "Payment deducted from wallet",
      icon: status === "FAILURE" ? "cancelCircle" : "checkCircle",
      iconColor: status === "FAILURE" ? "iconError" : "iconSuccess",
    })
    steps.push({
      title: `Payment credited to recipient’s bank`,
      iconColor:
        status === "SUCCESS"
          ? "iconSuccess"
          : status === "FAILURE" || status === "REVERSED"
          ? "iconError"
          : "iconWarning",
      icon:
        status === "SUCCESS"
          ? "checkCircle"
          : status === "FAILURE" || status === "REVERSED"
          ? "cancelCircle"
          : "iconWarning",
    })
  } else {
    steps.push({
      title: "Payment deducted from wallet",
      icon: "iconWarning",
      iconColor: "iconWarning",
    })
  }
  if (isReversed) {
    steps.push({
      title: "Payment reversed to wallet",
      iconColor: "iconSuccess",
      icon: "checkCircle",
    })
  }
  return steps
}

function PaymentJourney({
  isReversed,
  status,
}: {
  isReversed: boolean
  status: PaymentStatus
}) {
  const steps = getPaymentStatusInSteps(isReversed, status)
  return (
    <Stack gap="6" as="ul" justifyContent="center" position="relative">
      {steps.map((step) => (
        <Inline
          key={step.title}
          as="li"
          alignItems="center"
          gap="2"
          className="z-[1]"
        >
          <Box backgroundColor="white" rounded="full">
            <PaymentsIcons name={step.icon} color={step.iconColor} />
          </Box>
          <Text fontSize="b3">{step.title}</Text>
        </Inline>
      ))}
      <Stack
        height="full"
        width="px"
        backgroundColor="borderOutline"
        position="absolute"
        top="0"
        zIndex="0"
        className="left-3"
      ></Stack>
    </Stack>
  )
}

export function getStatusTitle({
  type,
  status,
  transactionType,
}: {
  status: PaymentStatus
  type: "CREDIT" | "DEBIT"
  transactionType?: PaymentTransactionTypes
}) {
  switch (status) {
    case "PENDING":
    case "DEEMED":
      return "Pending"
    case "REQUESTED":
      return "Unpaid"
    case "FAILURE":
    case "REVERSED":
      return "Failed"
    case "SUCCESS":
      return type === "CREDIT" ? "Received" : "Paid"
    case "REFUNDED":
      return "Refunded"
    case "EXPIRED":
      return "Expired"
    default:
      return ""
  }
}

export function getTextColorForStatus({
  status,
}: {
  status: PaymentStatus
}): React.ComponentProps<typeof Text>["color"] {
  switch (status) {
    case "PENDING":
    case "DEEMED":
      return "textWarning"
    case "FAILURE":
    case "REVERSED":
      return "textError"
    case "SUCCESS":
      return "textSuccess"
    case "REFUNDED":
      return "textSuccess"
    default:
      return "textMedium"
  }
}

export function getHelperIconForStatus({
  status,
  ...props
}: IconProps & {
  status: PaymentStatus
}): React.ReactNode | undefined {
  switch (status) {
    case "PENDING":
    case "DEEMED":
    case "REQUESTED":
      return <ClockIcon {...props} />
    case "REJECTED":
      return <DisabledIcon {...props} />
    case "EXPIRED":
      return <HourGlassEmptyIcon {...props} />
    case "FAILURE":
    case "REVERSED":
      return <InformationWarningIcon {...props} />
    default:
      return undefined
  }
}

function PaymentStatusTag({
  status,
  type,
  transactionType,
}: {
  status: PaymentStatus
  type: "CREDIT" | "DEBIT"
  transactionType?: PaymentTransactionTypes
}) {
  const tagTitle: string = useMemo(() => {
    return getStatusTitle({ status, type, transactionType })
  }, [status, type, transactionType])

  const bgColor: React.ComponentProps<typeof Box>["bgColor"] = useMemo(() => {
    switch (status) {
      case "SUCCESS":
      case "REFUNDED":
        return "surfaceSuccess"
      case "FAILURE":
      case "REVERSED":
        return "iconError"
      case "EXPIRED":
        return "iconMedium"
      default:
        return "surfaceWarning"
    }
  }, [status])

  return (
    <Box rounded="md" paddingY="1" paddingX="2" backgroundColor={bgColor}>
      <Text fontSize="c2" color="textOnSurface">
        {tagTitle}
      </Text>
    </Box>
  )
}

function PaymentStatusBanner({ status }: { status: PaymentStatus }) {
  const title: string = useMemo(() => {
    switch (status) {
      case "PENDING":
      case "DEEMED":
        return "We are still trying to complete this transaction. Please wait for a while."
      case "FAILURE":
        return "Sorry, we are unable to process this payment request"
      case "REVERSED":
        return "Amount is credited back to your wallet"
      default:
        return ""
    }
  }, [status])
  const helperIcon: React.ReactNode | undefined = useMemo(() => {
    switch (status) {
      case "PENDING":
      case "DEEMED":
        return <InformationWarningIcon />
      case "FAILURE":
        return <InformationCircleFilledIcon />
      case "REVERSED":
        return <CheckCircleSolidIcon />
      default:
        return undefined
    }
  }, [status])

  const colorStyles: {
    color: React.ComponentProps<typeof Box>["color"]
    bgColor: React.ComponentProps<typeof Box>["backgroundColor"]
  } = useMemo(() => {
    switch (status) {
      case "FAILURE":
        return { color: "textError", bgColor: "surfaceErrorLowest" }
      case "REVERSED":
        return { color: "textSuccess", bgColor: "surfaceSuccessLowest" }
      default:
        return { color: "textWarning", bgColor: "surfaceWarningLowest" }
    }
  }, [status])

  return (
    <Inline
      gap="3"
      backgroundColor={colorStyles.bgColor}
      rounded="md"
      padding="4"
      alignItems="center"
      color={colorStyles.color}
    >
      {helperIcon}
      <Text fontSize="c2">{title}</Text>
    </Inline>
  )
}

function TransactionReceiptWithDetails({
  to,
  from,
  type,
  status,
  amount,
  timestamp,
  otherDetails,
}: {
  amount: number
  type: "DEBIT" | "CREDIT"
  status: PaymentStatus
  timestamp?: Timestamp
  to?: PartiesInvolvedType
  from?: PartiesInvolvedType
  otherDetails: {
    [key: string]: string
  }
}) {
  return (
    <Stack backgroundColor="backgroundLight1">
      <Inline
        paddingY="2"
        paddingX="4"
        alignItems="center"
        justifyContent="between"
      >
        <CashbookWithNameIcon width="24" />
        <TransactionDate format="dd MMM yyyy, hh:mm a" timeStamp={timestamp} />
      </Inline>
      <Stack
        backgroundColor={
          status === "SUCCESS" || status === "REFUNDED"
            ? "surfaceSuccessLowest"
            : status === "FAILURE" || status === "REVERSED"
            ? "surfaceErrorLowest"
            : "surfaceNeutralLowest"
        }
        gap="2"
        marginX="4"
        padding="4"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          fontSize="c1"
          color={
            status === "SUCCESS" || status === "REFUNDED"
              ? "textCashIn"
              : status === "FAILURE" || status === "REVERSED"
              ? "textError"
              : "textMedium"
          }
        >
          {status === "SUCCESS" && type === "DEBIT"
            ? "Paid Successfully"
            : status === "SUCCESS" && type === "CREDIT"
            ? "Received Successfully"
            : status === "REFUNDED" && type === "CREDIT"
            ? "Refund Received Successfully"
            : status === "FAILURE" || status === "REVERSED"
            ? "Transaction Failed"
            : ""}
        </Text>
        <Inline alignItems="center" gap="2">
          <Amount fontSize="h1" currency="inr" amount={amount || 0} />
          {status === "PENDING" ? (
            <ClockIcon color="iconWarning" />
          ) : (
            <SmallArrowHeadUpIcon
              rotate={type === "CREDIT" ? "180" : undefined}
              color={type === "DEBIT" ? "iconCashOut" : "iconCashIn"}
            />
          )}
        </Inline>
      </Stack>
      <Stack padding="4" gap="4">
        {to ? (
          <Inline gap="4" whiteSpace="preserve">
            <Avatar
              id={to.id}
              name={to.title || to.name}
              size="8"
              fontSize="b1"
            />
            <Stack gap="1">
              <Text fontSize="b3">To: {to.title || to.name}</Text>
              {to.subtext && (
                <Inline alignItems="center" gap="1" whiteSpace="preserve">
                  {to?.partyType === "ENTITY" ? null : (
                    <PaymentsIcons color="iconMedium" size="4" name={to.icon} />
                  )}
                  <Text fontSize="c3">{to.subtext}</Text>
                </Inline>
              )}
            </Stack>
          </Inline>
        ) : null}
        {from ? (
          <Inline gap="4">
            <Avatar
              id={from.id}
              name={from.title || from.name}
              size="8"
              fontSize="b1"
            />
            <Stack gap="1">
              <Text fontSize="b3">From: {from.title || from.name}</Text>
              {from.subtext && (
                <Inline gap="1" alignItems="center" whiteSpace="preserve">
                  <PaymentsIcons size="4" color="iconMedium" name={from.icon} />
                  <Text fontSize="c3">{from.subtext}</Text>
                </Inline>
              )}
            </Stack>
          </Inline>
        ) : null}
      </Stack>
      <Stack
        marginX="4"
        paddingX="3"
        paddingY="2"
        backgroundColor="surfaceNeutralLowest"
      >
        <Text color="textMedium" fontSize="c2">
          Other Details
        </Text>
      </Stack>
      <Stack as="ul" gap="4" paddingX="4" paddingY="5">
        {Object.keys(otherDetails).map((key) => {
          return otherDetails[key] ? (
            <Stack gap="2" key={key} paddingX="4">
              <Text fontSize="c2" color="textMedium">
                {key}
              </Text>
              <Text fontSize="b3">{otherDetails[key]}</Text>
            </Stack>
          ) : null
        })}
      </Stack>
      {to?.icon === "upi" || from?.icon === "upi" ? (
        <Inline
          marginX="4"
          paddingY="3"
          backgroundColor="surfaceNeutralLowest"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="24">
            <img src={PoweredByUpi} alt="Powered By UPI" />
          </Box>
        </Inline>
      ) : null}
    </Stack>
  )
}

export function CopyTransactionsToCashbook({
  children,
  ...props
}: React.ComponentProps<typeof CopyToCashbook> & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        placement="right"
        onClose={state.close}
        title="Select Book"
      >
        <CopyToCashbook {...props} onClose={state.close} />
      </Modal>
    </>
  )
}

function CopyToCashbook({
  businessId,
  transactions,
  onClose,
}: {
  businessId: string
  transactions: PaymentsTransaction[]
  onClose?: () => void
}) {
  const { business } = useBusiness(businessId)
  const { books } = useBooksForBusinessId(businessId)

  const [q, setQuery] = useState<string>("")
  const [shouldSkipDuplicates, setShouldSkipDuplicates] =
    useState<boolean>(false)

  const [selectedBook, setSelectedBook] = useState<TBook | null>(null)

  const duplicatedTransactionsCount = useMemo(() => {
    if (selectedBook?.id) {
      return transactions.filter((t) => t.copied_to_books?.[selectedBook.id])
        .length
    }
    return 0
  }, [selectedBook?.id, transactions])

  const filteredBooks = useMemo(() => {
    return (
      books?.filter((book) =>
        book.name.toLowerCase().includes(q.toLowerCase())
      ) || []
    )
  }, [books, q])

  const transactionsToCopy = useMemo(() => {
    if (shouldSkipDuplicates && selectedBook?.id) {
      return [
        ...transactions.filter((t) => !t.copied_to_books?.[selectedBook?.id]),
      ]
    }
    return [...transactions]
  }, [selectedBook?.id, shouldSkipDuplicates, transactions])

  const npciTransactionIds = useMemo(() => {
    if (shouldSkipDuplicates && selectedBook?.id) {
      return transactions
        .filter((t) => !t.copied_to_books?.[selectedBook?.id])
        .map((t) => t.npci_txn_id)
    }
    return transactions.map((t) => t.npci_txn_id)
  }, [selectedBook?.id, shouldSkipDuplicates, transactions])

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Stack gap="4">
            <Text fontSize="b3">
              Select a book to copy {transactions.length}{" "}
              {pluralize("transaction", transactions.length)} in {business.name}
            </Text>
            <Inline
              position="relative"
              rounded="md"
              height="10"
              paddingRight="2"
              alignItems="stretch"
              gap="6"
              borderWidth="1"
              backgroundColor="backgroundLight1"
              width="full"
              borderColor="borderOutline"
              className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
            >
              <input
                type="search"
                name="q"
                autoComplete="off"
                placeholder="Search book name..."
                value={q}
                onChange={(e) => setQuery(e.currentTarget.value)}
                className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
              />
              <Inline
                as="button"
                type="button"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  if (q) setQuery("")
                }}
              >
                {q.length ? (
                  <CancelIcon color="gray900" />
                ) : (
                  <SearchIcon color="gray500" />
                )}
              </Inline>
            </Inline>
          </Stack>
          <Stack as="ul">
            {filteredBooks?.length ? (
              filteredBooks.map((b, i) => {
                const isSelected = selectedBook?.id === b.id
                return (
                  <Inline
                    as="li"
                    key={b.id}
                    gap="4"
                    borderBottomWidth={
                      i !== filteredBooks.length - 1 ? "1" : "0"
                    }
                    onClick={() => {
                      if (selectedBook?.id !== b.id) {
                        setSelectedBook(b)
                      }
                    }}
                    cursor="pointer"
                    paddingY="4"
                  >
                    <Box paddingTop="1">
                      <Radio isSelected={isSelected} />
                    </Box>
                    <Stack gap="2">
                      <Text fontSize="s3">{b.name}</Text>
                      {b?.createdAt ? (
                        <Text fontSize="b3" color="textMedium">
                          Created On:{" "}
                          {formatDate(
                            timeStampToDate(b.createdAt),
                            "MMM do yyyy"
                          )}{" "}
                          | {b?.sharedWith?.length} Members
                        </Text>
                      ) : null}
                    </Stack>
                  </Inline>
                )
              })
            ) : (
              <Stack>
                {q.length ? (
                  <Stack gap="2">
                    <Text fontSize="b1">No results found!</Text>
                    <Text fontSize="b3" color="textMedium">
                      No results found for "{q}". Please search for a valid
                      book.
                    </Text>
                  </Stack>
                ) : (
                  <Stack gap="2">
                    <Text fontSize="b1">No books found!</Text>
                    <Text fontSize="b3" color="textMedium">
                      There are no books available in business. You can add new
                      books and then try again!.
                    </Text>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter position="relative">
        {selectedBook?.id ? (
          <Inline
            position="absolute"
            className="-top-[76%]"
            left="0"
            width="full"
            backgroundColor="surfaceNeutralLowest"
            justifyContent="between"
            alignItems="center"
            paddingY="4"
            paddingX="6"
            as="button"
            gap="6"
          >
            <Stack textAlign="left" gap="2" width="full">
              <Text fontSize="c2" color="textMedium">
                Copy from
              </Text>
              <Text fontSize="b3">Payments</Text>
            </Stack>
            <Box>
              <ArrowLeftIcon className="rotate-180" />
            </Box>
            <Stack textAlign="left" width="full" gap="2">
              <Text fontSize="c2" color="textMedium">
                Paste to
              </Text>
              <Text fontSize="b3">{selectedBook.name}</Text>
            </Stack>
          </Inline>
        ) : null}
        {selectedBook?.id ? (
          <CopyTransactionConfirmationModal
            businessName={business.name}
            bookName={selectedBook?.name || ""}
            destBookId={selectedBook?.id || ""}
            businessId={businessId}
            totalTransactions={npciTransactionIds.length}
            onSuccess={() => {
              onClose?.()
            }}
          >
            {({ open: openCopiedSuccessfullyModal }) => (
              <MissingFieldsInfoModal
                transactions={transactionsToCopy}
                destinationBookId={selectedBook?.id || ""}
                businessId={businessId}
                destinationBookName={selectedBook?.name || ""}
                onSuccess={openCopiedSuccessfullyModal}
              >
                {({ open: openMissingFieldsModal }) => (
                  <ConfirmCopyTransactionModal
                    businessName={business.name}
                    bookName={selectedBook?.name || ""}
                    duplicatedTransactionsCount={duplicatedTransactionsCount}
                    npciTransactionIds={npciTransactionIds}
                    destBookId={selectedBook?.id || ""}
                    businessId={businessId}
                    setShouldSkipDuplicates={setShouldSkipDuplicates}
                    onSuccess={onClose}
                    book={selectedBook}
                    handleDuplicateEntries={openMissingFieldsModal}
                  >
                    {({ open: openConfirmationModal }) => (
                      <CBButton
                        level="primary"
                        size="lg"
                        onClick={openConfirmationModal}
                        disabled={Boolean(!selectedBook?.id)}
                      >
                        Copy {transactions.length}{" "}
                        {pluralize("Transaction", transactions.length)}
                      </CBButton>
                    )}
                  </ConfirmCopyTransactionModal>
                )}
              </MissingFieldsInfoModal>
            )}
          </CopyTransactionConfirmationModal>
        ) : null}
      </ModalFooter>
    </>
  )
}

function MissingFieldsInfoModal({
  transactions,
  children,
  destinationBookId,
  businessId,
  destinationBookName,
  onSuccess,
}: {
  children: (props: { open: () => void }) => React.ReactNode
  transactions: Array<PaymentsTransaction>
  destinationBookId: string | undefined
  businessId: string
  destinationBookName: string
  onSuccess: () => void
}) {
  const { book } = useBook(destinationBookId || "")
  const confirmation = useOverlayTriggerState({})
  const numOfEntries = transactions.length
  const { businessTeam } = useBusiness(businessId)
  const [transactionEntries, setTransactionEntries] = useState<
    (PaymentsTransaction & {
      category?: {
        uuid: string
        name: string
      }
      billAlreadyAttached?: boolean
    })[]
  >([])
  const allMandatoyFields = getAllMandatoryFields(
    book?.preferences,
    book?.customFields
  )
  const allEntriesCompleted = checkIfAllEntriesAreComplete(
    transactionEntries,
    allMandatoyFields
  )
  const [copyTransactionsLoading, setCopyTransactionsLoading] = useState(false)
  const copyToCashbook = useCopyToCashbook()
  const [selectedTransactionIds, setSelectedTransactionIds] = useState<
    string[]
  >([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [currentlyEditedTransactionId, setCurrentlyEditedTransactionId] =
    useState("")

  useEffect(() => {
    if (confirmation.isOpen) {
      setTransactionEntries([...transactions])
    }
  }, [confirmation.isOpen, transactions])

  const isBillAttachedInSelectedTransaction = useMemo(() => {
    const filteredTransactions = transactions.filter(
      (transaction) => transaction.npci_txn_id === currentlyEditedTransactionId
    )
    if (!filteredTransactions.length) return false
    return Boolean(filteredTransactions[0]?.attachments?.length)
  }, [currentlyEditedTransactionId, transactions])

  const updateTransactionEntries = (
    transaction: any,
    transactionId: string
  ) => {
    transactionEntries.forEach((transactionEntry, index) => {
      if (transactionEntry.npci_txn_id === transactionId) {
        const customFieldObjects: { [key: string]: string } = {}
        book?.customFields &&
          Object.keys(book.customFields).forEach((key: string) => {
            if (transaction[key]) {
              customFieldObjects[key] = transaction[key]
            }
          })

        const transactionClone: PaymentsTransaction & {
          category: TBookEntryField | undefined
        } = {
          ...transactionEntry,
          note: transaction.remark,
          category: transaction.category,
          ...customFieldObjects,
        }
        transactionEntries[index] = transactionClone
      }
    })
    setTransactionEntries([...transactionEntries])
  }

  const sectionListData = useMemo(() => {
    const entries: TTransaction[] = []
    transactionEntries.forEach((transaction: any) => {
      const transactionDateTimeStamp: Timestamp | undefined = transformDate(
        transaction.created_at
      )
      if (transactionDateTimeStamp) {
        const customFieldObjects: { [key: string]: string } = {}
        book?.customFields &&
          Object.keys(book.customFields).forEach((key: string) => {
            customFieldObjects[key] = transaction[key]
          })
        const attachments: { [key: string]: EntryAttachmentInfo } = {}
        if (transaction.attachments && transaction.attachments.length) {
          transaction.attachments.forEach((attachment: any, index: number) => {
            if (attachment.id) {
              attachments[attachment.id] = {
                url: attachment.file_path || "",
                mimeType: attachment.mime_type || "",
                id: attachment.id,
                thumbUrl: attachment.thumbnail_path,
                fileName: `Attachment ${index + 1}`,
              }
            }
          })
        }
        entries.push({
          amount: Number(transaction.amount),
          date: transactionDateTimeStamp,
          remark: transaction.note || transaction.description,
          type: transaction.type === "CREDIT" ? "cash-in" : "cash-out",
          paymentModeId: "cashbook_upi",
          partyId: transaction.party?.name,
          createdBy: transaction.user_id || "",
          updatedBy: transaction.user_id || "",
          sharable: false,
          id: transaction.npci_txn_id,
          paymentMode: {
            uuid: "cashbook_upi",
            name: "CashBook UPI",
          },
          party: {
            uuid: transaction.party?.id || "",
            name: transaction.party?.name || "",
          },
          category: transaction.category,
          cashBookId: destinationBookId || "",
          ...customFieldObjects,
          attachments,
        })
      }
    })
    const transactionsListing = getSectionWiseEntriesList(entries)
    return transactionsListing.flatListData
  }, [destinationBookId, transactionEntries, book?.customFields])

  const onCopyTransactions = async () => {
    const npciTransactionIds: string[] = []
    const payload: { [key: string]: TTransaction } = {}
    Object.values(transactionEntries).forEach((transaction: any) => {
      npciTransactionIds.push(transaction.npci_txn_id)
      const transactionPayload: any = {}
      transactionPayload["remark"] =
        transaction.note || transaction.description || ""
      if (transaction?.category?.uuid) {
        transactionPayload["categoryId"] = transaction.category.uuid
      }
      book?.customFields &&
        Object.keys(book.customFields).forEach((key: string) => {
          transactionPayload[key] = transaction[key]
        })
      payload[`${transaction.npci_txn_id}`] = transactionPayload
    })
    trackEvent(TrackingEvents.PAYMENTS_COPY_STARTED, {
      numberOfSelectedEntries: npciTransactionIds.length,
    })
    setCopyTransactionsLoading(true)
    try {
      await copyToCashbook({
        businessId,
        destBookId: destinationBookId || "",
        npciTransactionIds,
        data: payload,
      })
      setCopyTransactionsLoading(false)
      trackEvent(TrackingEvents.PAYMENTS_COPY_COMPLETED, {
        numberOfSelectedEntries: npciTransactionIds.length,
      })
      onSuccess()
    } catch {
      setCopyTransactionsLoading(false)
    }
  }

  const onSelectTransaction = (transactionId: string) => {
    const selectedTransactionIdsClone = [...selectedTransactionIds]
    if (selectedTransactionIdsClone.includes(transactionId)) {
      setSelectedTransactionIds(
        selectedTransactionIdsClone.filter((txnId) => txnId !== transactionId)
      )
      if (selectAllChecked) {
        setSelectAllChecked(false)
      }
    } else {
      selectedTransactionIdsClone.push(transactionId)
      if (selectedTransactionIdsClone.length === transactionEntries.length) {
        setSelectAllChecked(true)
      }
      setSelectedTransactionIds(selectedTransactionIdsClone)
    }
  }

  const selectedTransactions = useMemo(() => {
    return transactionEntries.filter((transaction, index) => {
      return selectedTransactionIds.includes(transaction.npci_txn_id)
    })
  }, [selectedTransactionIds, transactionEntries])

  return (
    <>
      {children({
        open: confirmation.open,
      })}
      <Modal
        placement="right"
        isDismissable
        isOpen={confirmation.isOpen}
        onClose={confirmation.close}
        title="Incomplete Entries"
      >
        <ModalBody>
          <Box paddingBottom="24">
            <Alert status={allEntriesCompleted ? "success" : "warning"}>
              {allEntriesCompleted
                ? "Your entries are ready to be copied"
                : "These entries are missing some mandatory fields, required for the destination book. Please add them"}
            </Alert>
            {transactionEntries.length > 1 ? (
              <Inline>
                <Box
                  borderRightWidth={selectedTransactionIds.length ? "1" : "0"}
                  borderColor="borderPrimaryLow"
                  paddingRight="4"
                  marginRight="4"
                >
                  <Text fontSize="b3">
                    Select and update multiple entries at once
                  </Text>
                </Box>
                {selectedTransactionIds.length ? (
                  <Text fontSize="b3">{`${selectedTransactionIds.length} selected`}</Text>
                ) : null}
              </Inline>
            ) : null}
            {selectedTransactionIds.length ? (
              <Inline
                backgroundColor="backgroundLight3"
                paddingY="3"
                borderRadius="sm"
                marginTop="6"
              >
                <Inline
                  alignItems="center"
                  paddingX="4"
                  borderRightWidth="1"
                  borderColor="borderPrimaryLow"
                  onClick={() => {
                    setSelectAllChecked((prevState) => {
                      if (prevState) {
                        setSelectedTransactionIds([])
                      } else {
                        const npciTranscationsIds: string[] = []
                        transactionEntries.forEach((transaction) => {
                          npciTranscationsIds.push(transaction.npci_txn_id)
                        })
                        setSelectedTransactionIds(npciTranscationsIds)
                      }
                      return !prevState
                    })
                  }}
                  cursor="pointer"
                >
                  <Checkbox size="4" isSelected={selectAllChecked} />
                  <Box marginLeft="2">
                    <Text fontSize="c1">
                      {selectAllChecked ? "Unselect All" : "Select All"}
                    </Text>
                  </Box>
                </Inline>
                {!book?.preferences?.categoriesDisabled ? (
                  <PerformChangeFieldsInModal
                    book={book}
                    transactions={selectedTransactions as any}
                    onSuccess={() => null}
                    updateAllTransactions={(selectedField) => {
                      const transactionEntriesClone = [...transactionEntries]
                      transactionEntries.forEach((transaction, index) => {
                        if (
                          selectedTransactionIds.includes(
                            transaction.npci_txn_id
                          )
                        ) {
                          const transactionClone = { ...transaction }
                          transactionClone["category"] = selectedField
                          transactionEntriesClone[index] = transactionClone
                        }
                      })
                      setTransactionEntries(transactionEntriesClone)
                    }}
                  >
                    {({ handleAction }) => (
                      <Inline
                        alignItems="center"
                        paddingX="4"
                        borderRightWidth="1"
                        borderColor="borderPrimaryLow"
                        cursor="pointer"
                        onClick={() => {
                          handleAction("category")
                        }}
                      >
                        <PlusIcon color="iconPrimary" size="5" />
                        <Box marginLeft="2">
                          <Text fontSize="c1">Add Category</Text>
                        </Box>
                      </Inline>
                    )}
                  </PerformChangeFieldsInModal>
                ) : null}
                {book?.customFields && Object.keys(book.customFields) ? (
                  <FieldCustomFieldsFormInDialog
                    transactionsCount={selectedTransactionIds.length}
                    book={book}
                    updateCustomFieldsInSelectedTransactions={(
                      customFields: Record<string, string | undefined>
                    ) => {
                      const transactionEntriesClone = [...transactionEntries]
                      transactionEntries.forEach((transaction, index) => {
                        if (
                          selectedTransactionIds.includes(
                            transaction.npci_txn_id
                          )
                        ) {
                          const transactionClone: any = { ...transaction }
                          customFields &&
                            Object.keys(customFields).forEach((key: string) => {
                              transactionClone[key] = customFields[key]
                            })
                          transactionEntriesClone[index] = transactionClone
                        }
                      })
                      setTransactionEntries(transactionEntriesClone)
                    }}
                  >
                    {({ edit }) => (
                      <Inline
                        alignItems="center"
                        paddingX="4"
                        cursor="pointer"
                        onClick={edit}
                      >
                        <PlusIcon color="iconPrimary" size="5" />
                        <Box marginLeft="2">
                          <Text fontSize="c1">Fill Custom Fields</Text>
                        </Box>
                      </Inline>
                    )}
                  </FieldCustomFieldsFormInDialog>
                ) : null}
              </Inline>
            ) : null}
            <Stack>
              {sectionListData.map((listItem: any) => {
                if (typeof listItem === "string") {
                  return (
                    <Box marginTop="6">
                      <Text fontSize="s4" color="textMedium">
                        {listItem}
                      </Text>
                    </Box>
                  )
                }
                const memberDetails = businessTeam.filter(
                  (member) => member.uid === listItem.createdBy
                )
                const isDebitTransaction = listItem?.type === "cash-out"
                const isSelected = selectedTransactionIds.includes(listItem.id)
                const bookHasCustomFields =
                  book.customFields && Object.keys(book.customFields).length
                let allCustomFieldsFilled = true
                book.customFields &&
                  Object.keys(book.customFields as any).forEach((key) => {
                    if (
                      !listItem[key] &&
                      book.customFields &&
                      book.customFields[key]?.required
                    ) {
                      allCustomFieldsFilled = false
                    }
                  })

                return (
                  <EditTransactionInDialog
                    book={book}
                    transaction={listItem}
                    transactionId={""}
                    involvedUsers={[]}
                    updateTransaction={updateTransactionEntries}
                    isBillAttachedInPaymentsTransaction={
                      isBillAttachedInSelectedTransaction
                    }
                  >
                    {({ edit }) => (
                      <Inline
                        marginTop="6"
                        borderBottomWidth="1"
                        borderColor="borderDividers"
                        paddingBottom="4"
                      >
                        {transactionEntries?.length > 1 ? (
                          <Stack justifyContent="start">
                            <Checkbox
                              size="4"
                              isSelected={isSelected}
                              onClick={() => {
                                onSelectTransaction(listItem.id)
                              }}
                            />
                          </Stack>
                        ) : null}
                        <Stack
                          flex="1"
                          marginLeft="4"
                          onClick={() => {
                            setCurrentlyEditedTransactionId(listItem.id)
                            edit()
                          }}
                        >
                          <Inline justifyContent="between">
                            <Inline flex="1">
                              <Text fontSize="s3">{listItem.party?.name}</Text>
                            </Inline>
                            <Text fontSize="s4" color={"textCashOut"}>{`${
                              isDebitTransaction ? "-" : "+"
                            } ₹ ${listItem.amount}`}</Text>
                          </Inline>
                          <Inline marginY="2">
                            <Inline
                              backgroundColor="surfaceAlt1Lowest"
                              paddingX="3"
                              paddingY="1"
                              color="textAlt1"
                            >
                              {listItem.paymentMode?.name}
                            </Inline>
                            {listItem.category ? (
                              <Inline
                                fontSize="c1"
                                backgroundColor="surfaceAlt2Lowest"
                                paddingX="3"
                                paddingY="1"
                                marginLeft="4"
                                color="textAlt2"
                              >
                                <Text fontSize="c1">
                                  {listItem.category?.name}
                                </Text>
                              </Inline>
                            ) : null}
                          </Inline>
                          {bookHasCustomFields ? (
                            <Inline alignItems="center" marginBottom="2">
                              <Text
                                fontSize="c2"
                                color={
                                  allCustomFieldsFilled
                                    ? "textPrimary"
                                    : "textWarning"
                                }
                              >
                                More Fields
                              </Text>
                              <Box marginLeft="2">
                                {allCustomFieldsFilled ? (
                                  <ArrowRightIcon
                                    color="iconPrimary"
                                    size="4"
                                  />
                                ) : (
                                  <InformationWarningIcon
                                    color="iconWarning"
                                    size="4"
                                  />
                                )}
                              </Box>
                            </Inline>
                          ) : null}
                          {listItem?.attachments &&
                          Object.keys(listItem?.attachments).length ? (
                            <Box marginBottom="2">
                              <Text fontSize="sm" as="span" color="gray500">
                                <AttachmentIcon className="mr-2" />
                                {Object.keys(listItem?.attachments).length}{" "}
                                {pluralize(
                                  "Attachment",
                                  Object.keys(listItem?.attachments).length
                                )}
                              </Text>
                            </Box>
                          ) : null}
                          <Text fontSize="c2" color="textMedium">
                            {`By ${memberDetails[0].name} at `}
                            <Time
                              fontSize="c2"
                              color="textMedium"
                              timeStamp={transformDate(listItem.date || null)}
                              format="hh:mm a"
                            />
                          </Text>
                        </Stack>
                      </Inline>
                    )}
                  </EditTransactionInDialog>
                )
              })}
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter position="relative">
          <Inline
            position="absolute"
            className="-top-[76%]"
            left="0"
            width="full"
            backgroundColor="surfaceNeutralLowest"
            justifyContent="between"
            alignItems="center"
            paddingY="4"
            paddingX="6"
            as="button"
            gap="6"
          >
            <Stack textAlign="left" gap="2" width="full">
              <Text fontSize="c2" color="textMedium">
                Copy from
              </Text>
              <Text fontSize="b3">Payments</Text>
            </Stack>
            <Box>
              <ArrowLeftIcon className="rotate-180" />
            </Box>
            <Stack textAlign="left" width="full" gap="2">
              <Text fontSize="c2" color="textMedium">
                Paste to
              </Text>
              <Text fontSize="b3">{destinationBookName}</Text>
            </Stack>
          </Inline>
          <CBButton
            size="lg"
            onClick={onCopyTransactions}
            level="primary"
            disabled={copyTransactionsLoading || !allEntriesCompleted}
          >
            {copyTransactionsLoading ? (
              <>
                <SpinnerIcon />
                {`Copying ${numOfEntries} ${pluralize(
                  "Transaction",
                  transactions.length
                )}`}
              </>
            ) : (
              <>
                {`Copy ${numOfEntries} ${pluralize(
                  "Transaction",
                  transactions.length
                )}`}
              </>
            )}
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

function ConfirmCopyTransactionModal({
  bookName,
  businessId,
  destBookId,
  businessName,
  npciTransactionIds,
  duplicatedTransactionsCount,
  children,
  onSuccess,
  setShouldSkipDuplicates,
  book,
  handleDuplicateEntries,
}: {
  bookName: string
  businessId: string
  destBookId: string
  businessName: string
  npciTransactionIds: string[]
  duplicatedTransactionsCount?: number
  onSuccess?: () => void
  setShouldSkipDuplicates: (bool: boolean) => void
  children: (props: { open: () => void }) => React.ReactNode
  book: TBook
  handleDuplicateEntries: () => void
}) {
  const navigate = useNavigate()
  const state = useOverlayTriggerState({})
  const copyToCashbook = useCopyToCashbook()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [copied, setCopied] = useState<boolean>(false)
  const [skipDuplicates, setSkipDuplicates] = useState<boolean>(false)

  const duplicateTCopy = useMemo(() => {
    return `${pluralize("transaction", duplicatedTransactionsCount)}`
  }, [duplicatedTransactionsCount])

  const transactionCopy = useMemo(() => {
    return `${pluralize("transaction", npciTransactionIds.length)}`
  }, [npciTransactionIds])

  const pointers_for_duplicates = useMemo(() => {
    return [
      duplicatedTransactionsCount === npciTransactionIds.length
        ? `${
            duplicatedTransactionsCount > 1 ? "These" : "This"
          } ${duplicateTCopy} already exist in this book `
        : `${duplicatedTransactionsCount} of ${npciTransactionIds.length} ${duplicateTCopy} already exist in this book`,
      `If you confirm, duplicate entry will be created in '${bookName}'`,
    ]
  }, [
    bookName,
    duplicateTCopy,
    duplicatedTransactionsCount,
    npciTransactionIds,
  ])

  const pointers = useMemo(() => {
    return [
      `${npciTransactionIds.length} ${pluralize(
        "transaction",
        npciTransactionIds.length
      )} will be copied in ‘${bookName}’ of ${businessName}`,
      `This will change the net balance of ’${bookName}’`,
    ]
  }, [bookName, businessName, npciTransactionIds])

  const showingDuplicates = duplicatedTransactionsCount && !skipDuplicates

  function onClose() {
    if (copied) {
      onSuccess?.()
    }
    setSkipDuplicates(false)
    setShouldSkipDuplicates(false)
    setCopied(false)
    state.close()
  }

  async function onCopyTransactions() {
    const allMandatoyFields = getAllMandatoryFields(
      book?.preferences,
      book?.customFields
    )
    if (
      (allMandatoyFields.includes("paymentMode") &&
        allMandatoyFields.length > 0) ||
      allMandatoyFields.length > 0
    ) {
      state.close()
      handleDuplicateEntries()
    } else {
      trackEvent(TrackingEvents.PAYMENTS_COPY_STARTED, {
        numberOfSelectedEntries: npciTransactionIds.length,
      })
      setError(null)
      setLoading(true)
      try {
        await copyToCashbook({ businessId, destBookId, npciTransactionIds })
        setLoading(false)
        setCopied(true)
        trackEvent(TrackingEvents.PAYMENTS_COPY_COMPLETED, {
          numberOfSelectedEntries: npciTransactionIds.length,
        })
      } catch (e) {
        const err = e as Error
        setLoading(false)
        setError(err.message)
      }
    }
  }

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        title={
          copied
            ? "Transaction Copied"
            : showingDuplicates
            ? `Duplicate ${duplicateTCopy} found`
            : `Copy ${transactionCopy}?`
        }
        isOpen={state.isOpen}
        onClose={onClose}
      >
        {copied ? (
          <ModalBody>
            <Stack gap="6" alignItems="center" justifyContent="center">
              <CheckCircleSolidIcon size="18" color="iconSuccess" />
              <Stack
                gap="3"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="s1" textTransform="capitalize">
                  {npciTransactionIds.length} {transactionCopy} Copied
                  Successfully
                </Text>
                <Text
                  fontSize="b3"
                  color="textMedium"
                  textTransform="capitalize"
                >
                  You can open{" "}
                  <Text as="span" fontSize="s4">
                    ‘{bookName}’
                  </Text>{" "}
                  in {businessName}
                </Text>
              </Stack>
            </Stack>
          </ModalBody>
        ) : (
          <ModalBody>
            <Stack gap="4">
              <Text fontSize="b3">Are you sure?</Text>
              <Stack as="ul" gap="4">
                {(showingDuplicates ? pointers_for_duplicates : pointers).map(
                  (pointer) => (
                    <Inline key={pointer} gap="4" alignItems="center">
                      <Circle size="2" backgroundColor="iconLow" />
                      <Text>{pointer}</Text>
                    </Inline>
                  )
                )}
              </Stack>
            </Stack>
            {error ? <Alert status="error">{error}</Alert> : null}
          </ModalBody>
        )}
        {copied ? (
          <ModalFooter>
            <CBButton
              size="lg"
              level="primary"
              iconPlacement="right"
              onClick={() => {
                navigate(
                  `/businesses/${businessId}/cashbooks/${destBookId}/transactions`
                )
              }}
            >
              Open {bookName} <ArrowRightIcon />{" "}
            </CBButton>
          </ModalFooter>
        ) : showingDuplicates ? (
          <ModalFooter>
            {duplicatedTransactionsCount === npciTransactionIds.length ? (
              <CBButton
                size="lg"
                level="primary"
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </CBButton>
            ) : (
              <CBButton
                level="primary"
                size="lg"
                loading={loading}
                onClick={() => {
                  setSkipDuplicates(true)
                  setShouldSkipDuplicates(true)
                }}
              >
                Skip Duplicates
              </CBButton>
            )}
            <CBButton size="lg" status="danger" onClick={onCopyTransactions}>
              {loading ? "Duplicating..." : "Confirm All"}
            </CBButton>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <CBButton
              level="primary"
              size="lg"
              loading={loading}
              onClick={onCopyTransactions}
            >
              {loading ? "Copying..." : "Yes"}
            </CBButton>
            <CBButton size="lg" disabled={loading} onClick={onClose}>
              No
            </CBButton>
          </ModalFooter>
        )}
      </Modal>
    </>
  )
}

type LogTransactionProps = Omit<
  LogTransactionFormProps,
  "onSubmit" | "bookId" | "minDate" | "maxDate" | "canUpdateEntryFields"
> & {
  book: TBook
  onSuccess?: (data: LogTransactionDataSchema) => void
}

type EditTransactionProps = LogTransactionProps & {
  transactionId: string
  involvedUsers: Array<{ id: string; name: string }>
  transaction: TTransaction
  updateTransaction: (transaction: TTransaction, transactionId: string) => void
}

export function EditTransactionInDialog({
  onSuccess,
  onCancel,
  children,
  transaction,
  book,
  transactionId,
  updateTransaction,
  ...props
}: EditTransactionProps & {
  children: (props: { edit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})

  const fieldsWithFieldIds: { [key: string]: string } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const t: any = transaction
    const fieldObj: { [key: string]: string } = {}
    Object.keys(book?.customFields || {})
      .filter((key) => t?.[key] && book?.customFields?.[key])
      .forEach((key) => {
        fieldObj[key] = t?.[key]
      })
    return fieldObj
  }, [book?.customFields, transaction])

  const initialValues = useMemo(() => {
    const entryFields: { [key: string]: TBookEntryField } = {}
    if (transaction.category) {
      entryFields["category"] = transaction.category
    }
    return {
      amount: transaction.amount,
      remark: transaction.remark,
      type: transaction.type,
      date: timeStampToDate(transaction.date),
      thumbUrl: transaction.thumbUrl,
      imageUrl: transaction.imageUrl,
      attachments: transaction.attachments,
      partyId: transaction.partyId,
      paymentModeId: transaction.paymentModeId,
      categoryId: transaction.categoryId,
      party: transaction.party,
      paymentMode: {
        uuid: "cashbook_upi",
        name: "CashBook UPI",
      },
      ...entryFields,
    }
  }, [transaction])

  return (
    <>
      {children({ edit: () => state.open() })}
      <Modal
        isOpen={state.isOpen}
        title="Edit Entry"
        isDismissable
        onClose={state.close}
        placement="right"
      >
        <SuspenseWithPerf
          traceId="loading_transaction_details_to_edit"
          fallback={<DataLoadingFallback label="Loading entry details.." />}
        >
          <LogTransactionForm
            {...props}
            bookId={book.id}
            parties={book.parties}
            categories={book.categories}
            paymentModes={book.paymentModes}
            partyPreferences={book.partyPreferences}
            partyDisabled={book.preferences?.partyDisabled}
            categoriesDisabled={book.preferences?.categoriesDisabled}
            categoriesRequired={Boolean(book.preferences?.categoriesRequired)}
            paymentModesDisabled={book.preferences?.paymentModesDisabled}
            paymentModesRequired={Boolean(
              book.preferences?.paymentModesRequired
            )}
            initialValues={initialValues}
            customFields={fieldsWithFieldIds}
            onSubmit={async (data: any) => {
              const customFields: Record<string, string | undefined> = {}
              book.customFields &&
                Object.keys(book.customFields).forEach((key: string) => {
                  customFields[key] = data[key]
                })
              updateTransaction(
                {
                  ...transaction,
                  remark: data.remark,
                  category: data.category,
                  ...customFields,
                },
                transaction.id
              )
              state.close()
            }}
            canUpdateEntryFields
            transactionId={transactionId}
            isFlowCopyFromPayments
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function CopyTransactionConfirmationModal({
  bookName,
  businessId,
  destBookId,
  businessName,
  totalTransactions,
  children,
  onSuccess,
}: {
  bookName: string
  businessId: string
  destBookId: string
  businessName: string
  totalTransactions: number
  children: (props: { open: () => void }) => React.ReactNode
  onSuccess: () => void
}) {
  const navigate = useNavigate()
  const state = useOverlayTriggerState({})

  const transactionCopy = useMemo(() => {
    return `${pluralize("transaction", totalTransactions)}`
  }, [totalTransactions])

  const onClose = () => {
    state.close()
    onSuccess()
  }

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal title="Transaction Copied" isOpen={state.isOpen} onClose={onClose}>
        <ModalBody>
          <Stack gap="6" alignItems="center" justifyContent="center">
            <CheckCircleSolidIcon size="18" color="iconSuccess" />
            <Stack
              gap="3"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="s1" textTransform="capitalize">
                {totalTransactions} {transactionCopy} Copied Successfully
              </Text>
              <Text fontSize="b3" color="textMedium" textTransform="capitalize">
                You can open{" "}
                <Text as="span" fontSize="s4">
                  ‘{bookName}’
                </Text>{" "}
                in {businessName}
              </Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton
            size="lg"
            level="primary"
            iconPlacement="right"
            onClick={() => {
              navigate(
                `/businesses/${businessId}/cashbooks/${destBookId}/transactions`
              )
            }}
          >
            Open {bookName} <ArrowRightIcon />{" "}
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function FieldCustomFieldsFormInDialog({
  children,
  book,
  updateCustomFieldsInSelectedTransactions,
  transactionsCount,
  ...props
}: {
  updateCustomFieldsInSelectedTransactions: (
    customFields: Record<string, string | undefined>
  ) => void
  children: (props: { edit: () => void }) => React.ReactNode
  book: TBook
  transactionsCount: number
}) {
  const state = useOverlayTriggerState({})

  const fieldsWithFieldIds: { [key: string]: string } = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fieldObj = {}
    return fieldObj
  }, [])

  return (
    <>
      {children({ edit: () => state.open() })}
      <Modal
        isOpen={state.isOpen}
        title="Fill Custom Fields"
        isDismissable
        onClose={state.close}
        placement="right"
      >
        <SuspenseWithPerf
          traceId="loading_transaction_details_to_edit"
          fallback={<DataLoadingFallback label="Loading entry details.." />}
        >
          <FillCustomFieldForm
            {...props}
            bookId={book.id}
            customFields={fieldsWithFieldIds}
            onSubmit={async (data) => {
              const customFields: Record<string, string | undefined> = {}
              book.customFields &&
                Object.keys(book.customFields).forEach((key: string) => {
                  customFields[key] = data[key]
                })
              updateCustomFieldsInSelectedTransactions(customFields)
              state.close()
            }}
            transactionCount={transactionsCount}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

export function FillCustomFieldForm({
  onSubmit,
  bookId,
  customFields: transactionCustoms,
  transactionCount,
}: {
  onSubmit: (data: Record<string, string | undefined>) => Promise<void>
  bookId: string
  customFields?: Record<string, string | undefined>
  transactionCount: number
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formikRef = useRef<any>() // Create a ref for Formik

  const { book } = useBook(bookId)

  const { customFields } = book

  const fields: TBookCustomField[] = useMemo(() => {
    return (
      Object.keys(customFields || {})
        .map((key) => {
          return {
            name: customFields?.[key].name,
            order: customFields?.[key].order,
            required: customFields?.[key].required,
            type: customFields?.[key].type,
            fieldName: key,
          } as TBookCustomField
        })
        .sort((a, b) => (a.order || 0) - (b.order || 0)) || []
    )
  }, [customFields])

  const navigate = useNavigate()

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ ...transactionCustoms }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={async (values, actions) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const v: any = values
        const errs: any = {}

        fields
          .filter((field) => field.required)
          .forEach((field) => {
            if (field.fieldName && !v?.[field.fieldName]) {
              errs[field.fieldName] = `${field.name} is a required field!`
            }
          })
        if (Object.keys(errs).length) {
          actions.setErrors(errs)
          actions.setTouched(errs)
          return
        }

        await onSubmit({
          ...values,
        })
      }}
    >
      {({ isSubmitting, values, submitForm }) => {
        return (
          <FillCustomFieldsConfirmationModal
            numberOfTransactions={transactionCount}
            onSuccess={submitForm}
          >
            {({ open }) => (
              <Form
                noValidate
                encType="multiple/form-data"
                key={values.submitCount}
              >
                <ModalBody className="relative">
                  {fields.length
                    ? fields.map((field) => {
                        return field.fieldName ? (
                          <FormField
                            name={field.fieldName}
                            type="text"
                            label={field.name}
                            actionLabel={
                              <Button
                                inline
                                onClick={() => {
                                  navigate("../settings/fields/custom-fields")
                                }}
                                className="px-1 text-gray-500 hover:text-blue-900"
                              >
                                <GearIcon size="4" title="Configure Field" />
                              </Button>
                            }
                            required={field.required}
                            key={field.fieldName}
                            placeholder={field.name}
                          />
                        ) : null
                      })
                    : null}
                </ModalBody>
                <ModalFooter
                  actionsLayout="row"
                  className="sticky bottom-0 z-10 bg-white"
                >
                  <Button disabled={isSubmitting} size="lg" onClick={open}>
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </FillCustomFieldsConfirmationModal>
        )
      }}
    </Formik>
  )
}

function FillCustomFieldsConfirmationModal({
  children,
  onSuccess,
  numberOfTransactions,
}: {
  onSuccess: () => void
  children: (props: { open: () => void }) => React.ReactNode
  numberOfTransactions: number
}) {
  const state = useOverlayTriggerState({})

  const pointers = useMemo(() => {
    return [
      `You have selected ${numberOfTransactions} ${pluralize(
        "entry",
        numberOfTransactions
      )}`,
      "This will update any existing entries that were previously filled in.",
    ]
  }, [numberOfTransactions])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Fill Custom Fields"
      >
        <ModalBody>
          <Stack as="ul" gap="4">
            <Text fontSize="b3">Are you sure?</Text>
            {pointers.map((pointer) => (
              <Inline as="li" gap="4" alignItems="center" key={pointer}>
                <Circle size="2" backgroundColor="iconLow" />
                <Text fontSize="b3">{pointer}</Text>
              </Inline>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton onClick={state.close} size="lg">
            Cancel
          </CBButton>
          <CBButton
            type="submit"
            size="lg"
            onClick={() => {
              state.close()
              onSuccess()
            }}
          >
            Yes
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

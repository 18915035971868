const endpoints = {
  userJourney: "obopay/journey/user",
  checkWalletEnabled: "obopay/user/check-wallet-enabled",
  masterWalletBalance: "obopay/business/balance",
  masterWalletTransactions: "obopay/transaction/va/list",
  businessTransactions: "obopay/transaction/business/list",
  getAllParties: "obopay/party/business/list",
  getUserParties: "obopay/party/list",
  transactionDetails: "obopay/transaction/details",
  addAttachment: "obopay/transaction/attachment/create",
  deleteAttachment: "obopay/transaction/attachment/delete",
  getAttachmentUrl: "obopay/transaction/attachment/get-url",
  getTransactionAttachments: "obopay/transaction/attachment/list",
  masterWalletTransactionsReport: "obopay/transaction/va/list/download",
  copyTransactions: "obopay/transaction/copy-to-book",
  downloadBusinessTransactionsReport:
    "obopay/transaction/business/list/download",
  deactivatedWallets: "obopay/business/deactivated-wallets",
  allWalletBalances: "obopay/user/balance/all",
  userProfile: "obopay/user/profile",
  walletSummary: "obopay/transaction/user/summary",
  walletTransactions: "obopay/transaction/user/list",
  updateTransactionDetails: "obopay/transaction/update-details",
  getSubscriptionPayments: "subscription/payments",
  getSubscriptionWalletCount: "subscription/wallet/count",
  pauseUnpauseWallet: "obopay/user/wallet/action",
  manageCategory: "category/manage",
  categoryList: "category/list",
  toggleSingleCategory: "category/toggle",
  category: "category",
  updateCategory: "category/update",
  assignCategory: "obopay/transaction/assign-category",
  deleteCategory: "category/delete",
}

export default endpoints

import {
  AddMoneyIcon,
  ArrowRightIcon,
  BankGradientIcon,
  BankPlaceholderIcon,
  Box,
  Button,
  DataLoadingFallback,
  Inline,
  SkeletonRows,
  SkeletonTitle,
  SpinnerIcon,
  Stack,
  Text,
  Time,
  TransactionDate,
  DocumentDownloadIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PDFIcon,
  ExcelFileIcon,
} from "@cashbook/web-components"

import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"

import ErrorBoundary from "../ErrorBoundary"

import { Amount } from "../support/Intl"

import {
  Avatar,
  DateSelectForPayments,
  GeneralErrorHandler,
  PAYMENTS_ICON_NAMES,
  PaymentsIcons,
  TransactionDetailsInModal,
  ExportMasterWalletTransactionsInModal,
} from "../Payments"
import {
  PaymentsTransaction,
  transformDate,
  useMasterWallet,
} from "@cashbook/data-store/payments"
import { TBusinessUser, useBusiness } from "@cashbook/data-store/businesses"
import { useMemo } from "react"
import { TUser, useProfile } from "@cashbook/data-store/users"
import { ViewMasterWalletDetailsInModal } from "../Payments/MasterWallet"
import { pluralize } from "@cashbook/util-general"
import classNames from "classnames"

export default function MasterWalletPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={
          <DataLoadingFallback label="Loading master wallet details..." />
        }
        traceId="loading_master_wallet_details"
      >
        <MasterWallet key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

type TYPE_OF_TRANSACTIONS_TYPES = {
  id: string
  title: string
  icon?: PAYMENTS_ICON_NAMES
}
const TYPE_OF_TRANSACTIONS: TYPE_OF_TRANSACTIONS_TYPES[] = [
  { id: "all", title: "All" },
  {
    id: "B2B",
    title: "Added to Master Wallet",
    icon: "addMoney",
  },
  { id: "B2C", title: "Added to Member Wallets", icon: "walletIn" },
]

function MasterWallet({ businessId }: { businessId: string }) {
  const { user } = useProfile()
  const { business, getTeamMemberInfoForId } = useBusiness(businessId)
  const {
    error,
    status,
    params,
    currentPage,
    lastPage,
    fetching,
    transactions,
    fetchingMore,
    masterWallet,
    areFiltersApplied,
    transactionsCount,
    fetchMore,
    refreshPage,
    resetFilters,
    handleChange,
    handleDateChange,
  } = useMasterWallet(businessId)

  const { from_datetime, to_datetime } = params

  return (
    <Stack
      key={businessId}
      bgColor="surfaceDefault"
      paddingY="8"
      gap="8"
      height="full"
      paddingX={{ xs: "4", md: "8" }}
      minHeight={{ xs: "screen", sm: "0" }}
    >
      {status === "in_progress" ? (
        <Stack gap="8">
          <Inline alignItems="center" justifyContent="between" gap="6">
            <Inline
              borderWidth="1"
              borderColor="borderOutline"
              rounded="md"
              paddingY="4"
              paddingX="6"
              justifyContent="between"
              maxWidth="2xl"
              width="full"
            >
              <Stack gap="2" width="full" maxWidth="xs">
                <SkeletonTitle height="3" />
                <SkeletonTitle width="full" />
              </Stack>
            </Inline>
            <Inline
              borderWidth="1"
              borderColor="borderOutline"
              rounded="md"
              paddingY="4"
              paddingX="6"
              justifyContent="between"
              maxWidth="sm"
              width="full"
            >
              <Stack gap="2" width="full" maxWidth="xs">
                <SkeletonTitle height="3" />
                <SkeletonTitle width="full" />
              </Stack>
            </Inline>
          </Inline>
          <Box
            rounded="md"
            as="table"
            borderWidth="1"
            borderColor="borderOutline"
          >
            <SkeletonRows numOfRows={10} numOfCols={9} />
          </Box>
        </Stack>
      ) : error ? (
        <Box height="full">
          <GeneralErrorHandler onRetry={refreshPage} />
        </Box>
      ) : (
        <Stack gap="8">
          <Inline
            flexDirection={{ lg: "row", xs: "col" }}
            alignItems={{ lg: "center", xs: "start" }}
            justifyContent="between"
            gap="6"
          >
            <Inline
              borderWidth="1"
              borderColor="borderOutline"
              rounded="md"
              paddingY="4"
              paddingX="6"
              justifyContent="between"
              maxWidth="2xl"
              width="full"
            >
              <Stack gap="2">
                <Text fontSize="c2">Master Wallet Balance</Text>
                <Amount
                  fontSize="s1"
                  currency="inr"
                  amount={Number(masterWallet?.balance) || 0}
                />
              </Stack>
              <ViewMasterWalletDetailsInModal
                from="addMoney"
                businessName={business.name}
                ifscCode={masterWallet?.ifsc_code}
                accountNumber={masterWallet?.account_no}
                corporateName={masterWallet?.corporateName}
              >
                {({ open }) => (
                  <Button
                    status="success"
                    size="lg"
                    level="primary"
                    onClick={open}
                  >
                    <Box>
                      <AddMoneyIcon />
                    </Box>
                    Add Money
                  </Button>
                )}
              </ViewMasterWalletDetailsInModal>
            </Inline>
            <ViewMasterWalletDetailsInModal
              from="details"
              businessName={business.name}
              ifscCode={masterWallet?.ifsc_code}
              accountNumber={masterWallet?.account_no}
              corporateName={masterWallet?.corporateName}
            >
              {({ open }) => (
                <Inline
                  width="full"
                  alignItems="center"
                  justifyContent="between"
                  borderWidth="1"
                  borderColor="borderOutline"
                  rounded="md"
                  maxWidth={{ lg: "md", xs: "full" }}
                  paddingX="6"
                  paddingY="7"
                  onClick={open}
                  cursor="pointer"
                  height="full"
                >
                  <Inline alignItems="center" gap="2">
                    <Box>
                      <BankGradientIcon />
                    </Box>
                    <Text fontSize="s4">View Master Wallet Details</Text>
                  </Inline>
                  <Box>
                    <ArrowRightIcon />
                  </Box>
                </Inline>
              )}
            </ViewMasterWalletDetailsInModal>
          </Inline>
          <Box>
            <form onSubmit={(e) => e.preventDefault()}>
              <Inline alignItems="center" justifyContent="between">
                <Text fontSize="s2">Account Statement</Text>
                <ExportMasterWalletTransactionsInModal
                  searchParams={params}
                  businessId={businessId}
                >
                  {({ onExport }) => (
                    <Menu>
                      <MenuButton inline>
                        <Inline gap="2" alignItems="center" padding="2">
                          <DocumentDownloadIcon size="5" />
                          <Text>Download PDF/Excel</Text>
                        </Inline>
                      </MenuButton>
                      <MenuList align="bottom-right">
                        <MenuItem
                          action="pdf-export"
                          className="whitespace-pre"
                          onClick={() => onExport("pdf")}
                        >
                          <Inline gap="2" alignItems="center">
                            <PDFIcon size="6" color="gray500" />
                            <Text as="span">PDF Report</Text>
                          </Inline>
                        </MenuItem>
                        <MenuItem
                          action="excel-export"
                          className="whitespace-pre"
                          onClick={() => onExport("csv")}
                        >
                          <Inline gap="2" alignItems="center">
                            <ExcelFileIcon size="6" color="gray500" />
                            <Text as="span">Excel Report</Text>
                          </Inline>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </ExportMasterWalletTransactionsInModal>
              </Inline>
              <Inline>
                <Inline paddingTop="4" gap="10" alignItems="center">
                  <Inline as="ul" gap="4">
                    {TYPE_OF_TRANSACTIONS.map((option) => {
                      const isSelected = params.transaction_type === option.id
                      return (
                        <Inline
                          as="li"
                          key={option.id}
                          borderWidth="1"
                          paddingLeft={option.icon ? "2" : "3"}
                          paddingRight="3"
                          paddingY="2"
                          height="10"
                          rounded="md"
                          alignItems="center"
                          cursor="pointer"
                          onClick={() => {
                            handleChange("transaction_type", option.id)
                          }}
                          gap="3"
                          color={isSelected ? "textPrimary" : undefined}
                          borderColor={
                            isSelected ? "borderPrimaryLow" : "borderOutline"
                          }
                          backgroundColor={
                            isSelected ? "surfacePrimaryLowest" : undefined
                          }
                          className={classNames({
                            "hover:bg-blue-5": !isSelected,
                          })}
                        >
                          {option.icon ? (
                            <PaymentsIcons
                              color={isSelected ? "iconPrimary" : "iconMedium"}
                              size="5"
                              name={option.icon}
                            />
                          ) : null}
                          <Text fontSize={isSelected ? "c1" : "c2"}>
                            {option.title}
                          </Text>
                        </Inline>
                      )
                    })}
                  </Inline>
                  <Box
                    className="w-[1px] lg:block hidden"
                    height="full"
                    backgroundColor="borderDividers"
                  />
                  <Box className="lg:block hidden">
                    <DateSelectForPayments
                      height="10"
                      to_datetime={to_datetime}
                      from_datetime={from_datetime}
                      onChange={(from, to, label) => {
                        handleDateChange(from, to, label)
                      }}
                    />
                  </Box>
                </Inline>
              </Inline>
            </form>
            {fetching ? (
              <Stack marginY="8" gap="4">
                <SkeletonTitle />
                <Box as="table">
                  <SkeletonRows numOfRows={7} />
                </Box>
              </Stack>
            ) : !transactions?.length ? (
              <Stack gap="8" className="py-32">
                <Stack
                  height="full"
                  alignItems="center"
                  justifyContent="center"
                  gap="2"
                >
                  <Text fontSize="s1">No transactions found</Text>
                  <Text fontSize="b3" color="textMedium">
                    Please adjust your filters and try again
                  </Text>
                </Stack>
                {areFiltersApplied ? (
                  <Box alignSelf="center">
                    <Button level="primary" onClick={resetFilters}>
                      Reset Filters
                    </Button>
                  </Box>
                ) : null}
              </Stack>
            ) : (
              <>
                {
                  <Box paddingTop="8">
                    <Stack gap="4">
                      <Text color="gray500" fontWeight="medium">
                        Showing 1-{transactions.length} of {transactionsCount}{" "}
                        {pluralize("transaction", transactionsCount)}
                      </Text>
                      <Box as="table" width="full" position="relative">
                        <Box as="thead" className="whitespace-pre">
                          <tr>
                            <Box
                              as="th"
                              position="sticky"
                              top="0"
                              paddingX="3"
                              paddingY="4"
                              bgColor="backgroundLight3"
                              className={`w-[130px]`}
                            >
                              <Text fontSize="s4">Date & Time</Text>
                            </Box>
                            <Box
                              as="th"
                              position="sticky"
                              top="0"
                              paddingX="3"
                              paddingY="4"
                              className="min-w-[150px]"
                              bgColor="backgroundLight3"
                            >
                              <Text fontSize="s4">Transaction by</Text>
                            </Box>
                            <Box
                              as="th"
                              position="sticky"
                              top="0"
                              paddingX="3"
                              paddingY="4"
                              className="min-w-[150px]"
                              bgColor="backgroundLight3"
                            >
                              <Text fontSize="s4">From</Text>
                            </Box>
                            <Box
                              as="th"
                              position="sticky"
                              top="0"
                              paddingX="3"
                              paddingY="4"
                              className="min-w-[150px]"
                              bgColor="backgroundLight3"
                            >
                              <Text fontSize="s4">To</Text>
                            </Box>
                            <Box
                              as="th"
                              position="sticky"
                              top="0"
                              paddingX="3"
                              paddingY="4"
                              className={`w-[150px]`}
                              bgColor="backgroundLight3"
                            >
                              <Inline justifyContent="end">
                                <Text fontSize="s4">Amount</Text>
                              </Inline>
                            </Box>
                            {params.transaction_type === "all" ? (
                              <Box
                                as="th"
                                position="sticky"
                                top="0"
                                paddingX="3"
                                paddingY="4"
                                bgColor="backgroundLight3"
                              >
                                <Inline justifyContent="end">
                                  <Text fontSize="s4">Closing Balance</Text>
                                </Inline>
                              </Box>
                            ) : null}
                          </tr>
                        </Box>
                        <Box as="tbody">
                          {transactions.map((transaction, i) => (
                            <TransactionItem
                              user={user}
                              key={transaction.id}
                              transaction={transaction}
                              removeClosingBalance={
                                params.transaction_type !== "all"
                              }
                              isLastTransaction={i === transactions.length - 1}
                              getBusinessUser={getTeamMemberInfoForId}
                            />
                          ))}
                        </Box>
                      </Box>
                      {(status !== "success" && !transactions.length) ||
                      currentPage === lastPage ? null : (
                        <Box>
                          <Button
                            level="primary"
                            onClick={fetchMore}
                            disabled={fetchingMore}
                          >
                            <Text fontSize="bt">Show More Transactions</Text>
                            {fetchingMore && <SpinnerIcon />}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                }
              </>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

type TransactionUser = {
  id: string
  name: string
  icon?: "bank" | "master_wallet"
}

function TransactionItem({
  user,
  transaction,
  getBusinessUser,
  isLastTransaction,
  removeClosingBalance,
}: {
  user: TUser
  isLastTransaction?: boolean
  removeClosingBalance?: boolean
  transaction: PaymentsTransaction
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const { id, ext_party_fid, created_by, transaction_type, business_id } =
    transaction

  const transactionBy = useMemo(() => {
    const bUser = getBusinessUser?.(created_by || "")
    if (user.uid === bUser?.id) {
      return { ...bUser, name: "You" }
    }
    return bUser
  }, [getBusinessUser, created_by, user.uid])

  const userInvolved = useMemo(() => {
    const bUser = getBusinessUser?.(ext_party_fid || "")
    if (user.uid === bUser?.id) {
      return { ...bUser, name: "Your Wallet" }
    }
    return bUser
  }, [ext_party_fid, getBusinessUser, user.uid])

  const transactionFrom: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
        return { id, name: "Bank Account", icon: "bank" }
      case "C2B":
        return { id: userInvolved?.id || id, name: userInvolved?.name || "" }
      default:
        return { id, name: "Master Wallet", icon: "master_wallet" }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  const transactionTo: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
      case "C2B":
        return { id, name: "Master Wallet", icon: "master_wallet" }
      default:
        return {
          id: userInvolved?.id || id,
          name: userInvolved?.name || "",
        }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  return (
    <TransactionDetailsInModal
      transactionId={id || "missing"}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth={isLastTransaction ? undefined : "1"}
          borderColor="borderDividers"
          onClick={open}
          className="cursor-pointer hover:bg-gray-100"
        >
          <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
            <Stack gap="2">
              <TransactionDate
                fontSize="b3"
                timeStamp={transformDate(transaction.timestamp)}
              />
              <Time
                fontSize="c2"
                color="textMedium"
                timeStamp={transformDate(transaction.timestamp)}
                format="hh:mm a"
              />
            </Stack>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            {transactionBy?.name ? (
              <Inline alignItems="center" gap="2">
                <Box>
                  <Avatar
                    id={transactionBy?.id || "missing"}
                    name={transactionBy?.name}
                    size="6"
                    fontSize="c2"
                  />
                </Box>

                <Text fontSize="b3" className="w-full line-clamp-1">
                  {transactionBy?.name}
                </Text>
              </Inline>
            ) : (
              <Text fontSize="b3">-</Text>
            )}
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionFrom.icon ? (
                  transactionFrom.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionFrom?.id || id}
                    name={transactionFrom?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionFrom?.name}
              </Text>
            </Inline>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionTo.icon ? (
                  transactionTo.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionTo?.id || id}
                    name={transactionTo?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionTo?.name}
              </Text>
            </Inline>
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline justifyContent="end">
              <Amount
                type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                amount={Number(transaction.amount)}
                currency="inr"
                fontWeight="semibold"
              />
            </Inline>
          </Box>
          {removeClosingBalance ? null : (
            <Box as="td" paddingX="3" paddingY="4" className="align-middle">
              <Inline justifyContent="end">
                <Amount
                  amount={Number(transaction.balance)}
                  fontSize="b3"
                  currency="inr"
                />
              </Inline>
            </Box>
          )}
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}

import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf, useUser } from "reactfire"
import {
  ArrowForwardIcon,
  Box,
  Button,
  Circle,
  DataLoadingFallback,
  Heading,
  Inline,
  PageMeta,
  Stack,
  Text,
} from "@cashbook/web-components"
import { useBusiness } from "@cashbook/data-store/businesses"
import CashbookCard from "../Payments/CashBookCard.png"
import UpiPaymentsImage from "../Payments/UpiPaymentsImage.png"
import CashBookIos from "../Payments/cashbook_app_ios.webp"
import CashBookAndroid from "../Payments/cashbook_app_android.webp"
import config from "../config"
import { PAYMENTS_ICON_NAMES, PaymentsIcons } from "../Payments"
import {
  useGetPaymentCategory,
  useManagePaymentCategory,
  useUserJourney,
  useUserPaymentsProfile,
} from "@cashbook/data-store/payments"
import { useMemo, useSyncExternalStore } from "react"
import classNames from "classnames"
import { useMount } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import {
  showPaymentsStore,
  useSyncedStorageState,
} from "@cashbook/data-store/storage"
import { NotInWeb } from "../support"

export default function PaymentsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading payments details..." />}
        traceId="loading_payments_details"
      >
        <PaymentsLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function RedirectToPaymentsDashboard({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { businessId } = useParams()
  if (!businessId) return <Navigate to={`${routePrefix}/`} />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/payments/dashboard`}
      replace
    />
  )
}

type PAYMENTS_TAB_IDS =
  | "dashboard"
  | "allTransactions"
  | "masterWallet"
  | "staffWallets"
  | "settings"
const PAYMENTS_DASHBOARD_OPTIONS: {
  to: string
  title: string
  id: PAYMENTS_TAB_IDS
}[] = [
  { to: "dashboard", title: "Dashboard", id: "dashboard" },
  {
    to: "all-transactions?type=expenses",
    title: "All Transactions",
    id: "allTransactions",
  },
  { to: "master-wallet", title: "Master Wallet", id: "masterWallet" },
  { to: "staff-wallets", title: "Member Wallets", id: "staffWallets" },
  { to: "settings", title: "Payment Settings", id: "settings" },
]
function PaymentsLayout({ businessId }: { businessId: string }) {
  const pathname = window.location.href
  const [searchParams] = useSearchParams()
  const { business, authTeamMemberDetails } = useBusiness(businessId)

  useUserJourney(businessId, authTeamMemberDetails.role.id)
  useUserPaymentsProfile(businessId, authTeamMemberDetails.uid)
  useManagePaymentCategory(businessId, authTeamMemberDetails.role.id)
  useGetPaymentCategory(businessId, authTeamMemberDetails.role.id)

  const removeGlobalHeader = useMemo(() => {
    if (searchParams.get("header") === "none") {
      return true
    }
    return false
  }, [searchParams])

  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  return (
    <>
      <PageMeta>
        <title>{business.name}</title>
      </PageMeta>
      <Box key={businessId} display="flex" flexDirection="col" height="full">
        {removeGlobalHeader ? null : (
          <PaymentsHeaderLayout
            path={pathname}
            businessName={business.name}
            paymentEnabled={
              showPayments[businessId]?.is_payments_enabled &&
              !!showPayments[business.id]?.corpShortName &&
              showPayments[business.id]?.business_kyc_status === "APPROVED"
            }
          />
        )}
        <Box flexGrow="1">
          {showPayments[businessId]?.is_payments_enabled &&
          !!showPayments[business.id]?.corpShortName ? (
            showPayments[business.id]?.business_kyc_status === "APPROVED" ? (
              <Outlet />
            ) : showPayments[business.id]?.business_kyc_status === "REDO" ||
              showPayments[business.id]?.business_kyc_status === "REJECTED" ? (
              <BusinessKycRedo
                remark={showPayments[business.id].business_kyc_remarks}
              />
            ) : (
              <BusinessKycNotApproved />
            )
          ) : showPayments[businessId]?.is_card_user &&
            showPayments[businessId]?.role === "owner" ? (
            <CardUserMigration
              isDeactivating={
                showPayments[businessId]?.card_payments_status ===
                "DEACTIVATING"
              }
            />
          ) : showPayments[businessId]?.show_payments_tab ? (
            <PaymentsNotEnabled />
          ) : null}
        </Box>
      </Box>
    </>
  )
}

function PaymentsHeaderLayout({
  path,
  businessName,
  paymentEnabled,
}: {
  path: string
  businessName: string
  paymentEnabled?: boolean
}) {
  const navigate = useNavigate()
  const { data: user } = useUser()

  const [newFeaturesIntroduced, setNewFeaturesIntroduced] =
    useSyncedStorageState<{
      [key: string]: string[]
    }>("newFeatures", {})

  const showNewBannerForEntryFields =
    !newFeaturesIntroduced[user?.uid || ""]?.includes("paymentCategories")

  useMount(() => {
    trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
      currentTab:
        PAYMENTS_DASHBOARD_OPTIONS.find((option) =>
          Boolean(path.includes(option.to.split("?")[0]))
        )?.id || "dashboard",
    })
  })
  return (
    <Box
      as="header"
      flexGrow="0"
      borderBottomWidth="1"
      borderColor="borderDividers"
    >
      <Box paddingX="8">
        <Stack>
          <Inline gap="3" alignItems="center" paddingY="6">
            <Heading as="h4" fontSize="s1" fontWeight="semibold">
              Payments
            </Heading>
            <Text as="span" fontSize="b3" color="textMedium">
              ({businessName})
            </Text>
          </Inline>
          {paymentEnabled ? (
            <Inline as="ul" gap="6" overflowY="auto">
              {PAYMENTS_DASHBOARD_OPTIONS.map((option) => {
                const isActivePath = Boolean(
                  path.includes(option.to.split("?")[0])
                )
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    key={option.to}
                    as="button"
                    padding="3"
                    roundedTop="md"
                    whiteSpace="nowrap"
                    borderColor="borderPrimary"
                    borderBottomWidth={isActivePath ? "2" : undefined}
                    backgroundColor={
                      isActivePath ? "surfacePrimaryLowest" : undefined
                    }
                    className={classNames({
                      "hover:bg-blue-5": !isActivePath,
                    })}
                    onClick={() => {
                      if (!isActivePath) {
                        navigate(option.to)
                        trackEvent(TrackingEvents.PAYMENTS_TAB_SWITCHED, {
                          currentTab: option.id,
                          previousTab:
                            PAYMENTS_DASHBOARD_OPTIONS.find(() => isActivePath)
                              ?.id || "dashboard",
                        })
                        if (option.title === "Payment Settings") {
                          setNewFeaturesIntroduced({
                            ...newFeaturesIntroduced,
                            [`${user?.uid}`]: [
                              ...(newFeaturesIntroduced[user?.uid || ""] ?? []),
                              "paymentCategories",
                            ],
                          })
                        }
                      }
                    }}
                  >
                    <Text
                      fontSize="s4"
                      color={isActivePath ? "textPrimary" : "textMedium"}
                    >
                      {option.title}
                    </Text>
                    {showNewBannerForEntryFields &&
                    option.title === "Payment Settings" ? (
                      <Circle
                        size="2"
                        className="ml-1"
                        backgroundColor="iconError"
                      />
                    ) : null}
                  </Box>
                )
              })}
            </Inline>
          ) : null}
        </Stack>
      </Box>
    </Box>
  )
}

const cashbookPaymentsFeatures: Array<{
  id: string
  title: string
  icon: PAYMENTS_ICON_NAMES
}> = [
  {
    id: "staffWallet",
    icon: "moneyTransfer",
    title: "Add money to staff wallet",
  },
  {
    id: "autoReports",
    title: "Get notified of every expense",
    icon: "notifiedExpenses",
  },
  {
    id: "staffWalletLimits",
    icon: "upiWalletLimits",
    title: "Set limits on Wallet Expenses",
  },
]

function PaymentsNotEnabled() {
  return (
    <Box paddingY="6">
      <Box
        paddingY="8"
        paddingX="10"
        marginX="8"
        backgroundColor="white"
        rounded="lg"
        borderWidth="1"
        borderColor="borderOutline"
        className="max-h-fit"
      >
        <Inline
          gap="12"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Inline gap="6" alignItems="center" flex="1">
            <Stack gap="4">
              <img
                src={UpiPaymentsImage}
                className="w-full max-w-[268px]"
                alt={`${config.appTitle} Wallet`}
              />
              <Button
                level="secondary"
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open("https://youtu.be/rONszBYkktM", "_blank")
                  }
                }}
              >
                Watch 1-min Video
              </Button>
            </Stack>
            <Stack gap="6">
              <Stack gap="2">
                <Text as="h2" fontSize="s1">
                  Activate ‘Payments’ on{" "}
                  <Text color="textPrimary" as="span">
                    {config.appTitle} Mobile App
                  </Text>{" "}
                  for Your Business!
                </Text>
                <Text fontSize="b3" color="textMedium">
                  You will be able to access {config.appTitle} payments on web
                </Text>
              </Stack>
              <Inline as="ul" gap="10">
                {cashbookPaymentsFeatures.map((feat) => (
                  <Stack
                    key={feat.id}
                    gap="4"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    className="max-w-[102px]"
                  >
                    <PaymentsIcons size="8" name={feat.icon} />
                    <Text fontSize="c2">{feat.title}</Text>
                  </Stack>
                ))}
              </Inline>
            </Stack>
          </Inline>
          <Box
            borderColor="borderOutline"
            className="border-l h-48 lg:block hidden"
          />
          <Stack
            gap="6"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text fontSize="b3">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookAndroid}
                    alt={`Download ${config.appTitle} app on your android devices`}
                  />
                </Box>
                <Text fontSize="c2">Android</Text>
              </Stack>
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookIos}
                    alt={`Download ${config.appTitle} app on your ios devices`}
                  />
                </Box>
                <Text fontSize="c2">iOS</Text>
              </Stack>
            </Inline>
          </Stack>
        </Inline>
      </Box>
    </Box>
  )
}

function BusinessKycNotApproved() {
  return (
    <Box paddingY="6">
      <Box
        paddingY="8"
        paddingX="10"
        marginX="8"
        backgroundColor="white"
        rounded="lg"
        borderWidth="1"
        borderColor="borderOutline"
        className="max-h-fit"
      >
        <Inline
          gap="12"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Inline gap="6" alignItems="center" flex="1">
            <Stack gap="4">
              <img
                src={UpiPaymentsImage}
                className="w-full max-w-[268px]"
                alt={`${config.appTitle} Wallet`}
              />
              <Button
                level="secondary"
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open("https://youtu.be/rONszBYkktM", "_blank")
                  }
                }}
              >
                Watch 1-min Video
              </Button>
            </Stack>
            <Stack gap="6">
              <Stack gap="2">
                <Text as="h1" fontSize="s1" color="textWarning">
                  Business KYC under review
                </Text>
                <Text fontSize="b3" color="textMedium">
                  (Expect completion within 48 hours)
                </Text>
              </Stack>
              <Inline as="ul" gap="10">
                {cashbookPaymentsFeatures.map((feat) => (
                  <Stack
                    key={feat.id}
                    gap="4"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    className="max-w-[102px]"
                  >
                    <PaymentsIcons size="8" name={feat.icon} />
                    <Text fontSize="c2">{feat.title}</Text>
                  </Stack>
                ))}
              </Inline>
            </Stack>
          </Inline>
          <Box
            borderColor="borderOutline"
            className="border-l h-48 lg:block hidden"
          />
          <Stack
            gap="6"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text fontSize="b3">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookAndroid}
                    alt={`Download ${config.appTitle} app on your android devices`}
                  />
                </Box>
                <Text fontSize="c2">Android</Text>
              </Stack>
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookIos}
                    alt={`Download ${config.appTitle} app on your ios devices`}
                  />
                </Box>
                <Text fontSize="c2">iOS</Text>
              </Stack>
            </Inline>
          </Stack>
        </Inline>
      </Box>
    </Box>
  )
}

function BusinessKycRedo({ remark }: { remark: string | undefined }) {
  return (
    <Box paddingY="6">
      <Box
        paddingY="8"
        paddingX="10"
        marginX="8"
        backgroundColor="white"
        rounded="lg"
        borderWidth="1"
        borderColor="borderOutline"
        className="max-h-fit"
      >
        <Inline
          gap="12"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Inline gap="6" alignItems="center" flex="1">
            <Stack gap="4">
              <img
                src={UpiPaymentsImage}
                className="w-full max-w-[268px]"
                alt={`${config.appTitle} Wallet`}
              />
              <NotInWeb heading="Use mobile app to REDO Business KYC">
                {({ onDisplay }) => (
                  <Button level="primary" onClick={onDisplay}>
                    Redo Business KYC
                  </Button>
                )}
              </NotInWeb>
            </Stack>
            <Stack gap="6" flex="1">
              <Stack gap="2">
                <Text as="h1" fontSize="h2" color="textError">
                  Business KYC Failed
                </Text>
                <Text fontSize="b3" color="textMedium">
                  {remark
                    ? `Reason: ${remark}`
                    : "Please submit valid documents"}
                </Text>
              </Stack>
              <Inline as="ul" gap="10">
                {cashbookPaymentsFeatures.map((feat) => (
                  <Stack
                    key={feat.id}
                    gap="4"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    className="max-w-[102px]"
                  >
                    <PaymentsIcons size="8" name={feat.icon} />
                    <Text fontSize="c2">{feat.title}</Text>
                  </Stack>
                ))}
              </Inline>
            </Stack>
          </Inline>
          <Box
            borderColor="borderOutline"
            className="border-l h-48 lg:block hidden"
          />
          <Stack
            gap="6"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Text fontSize="b3">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookAndroid}
                    alt={`Download ${config.appTitle} app on your android devices`}
                  />
                </Box>
                <Text fontSize="c2">Android</Text>
              </Stack>
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookIos}
                    alt={`Download ${config.appTitle} app on your ios devices`}
                  />
                </Box>
                <Text fontSize="c2">iOS</Text>
              </Stack>
            </Inline>
          </Stack>
        </Inline>
      </Box>
    </Box>
  )
}

function CardUserMigration({ isDeactivating }: { isDeactivating: boolean }) {
  return (
    <Box paddingY="6">
      <Box
        paddingY="8"
        paddingX="10"
        marginX="8"
        backgroundColor="white"
        rounded="lg"
        borderWidth="1"
        borderColor="borderOutline"
        className="max-h-fit"
      >
        <Inline
          gap="12"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Stack gap="6" alignItems="center" textAlign="center" flex="1">
            <Inline gap="4" alignItems="center">
              <img
                src={CashbookCard}
                className="w-full max-w-[200px] max-h-[200px]"
                alt={`${config.appTitle} Wallet`}
              />
              <ArrowForwardIcon style={{ width: 48, height: 48 }} />
              <img
                src={UpiPaymentsImage}
                className="w-full max-w-[200px] max-h-[200px]"
                alt={`${config.appTitle} Wallet`}
              />
            </Inline>
            <Stack gap="6">
              <Stack gap="2">
                <Text as="h2" fontSize="s1">
                  Moving away from{" "}
                  <Text color="textPrimary" as="span">
                    CashBook Cards
                  </Text>{" "}
                  to{" "}
                  <Text
                    color="textPrimary"
                    style={{ textDecoration: "stroke" }}
                    as="span"
                  >
                    CashBook UPI
                  </Text>
                  {" !"}
                </Text>
                <Text fontSize="b3" color="textMedium">
                  {isDeactivating ? (
                    "Get ready for an even better way to pay! While your CashBook Card is being deactivated, we're bringing you the speed and convenience of CashBook UPI."
                  ) : (
                    <>
                      Use{" "}
                      <Text color="textHigh" as="span">
                        {config.appTitle} Mobile App
                      </Text>{" "}
                      to complete the process.
                    </>
                  )}
                </Text>
              </Stack>
              <Inline as="ul" gap="10" alignSelf="center">
                {cashbookPaymentsFeatures.map((feat) => (
                  <Stack
                    key={feat.id}
                    gap="4"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    className="max-w-[102px]"
                  >
                    <PaymentsIcons size="8" name={feat.icon} />
                    <Text fontSize="c2">{feat.title}</Text>
                  </Stack>
                ))}
              </Inline>
            </Stack>
          </Stack>
          <Box
            borderColor="borderOutline"
            className="border-l h-48 lg:block hidden"
          />
          <Stack
            gap="6"
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            textAlign="center"
          >
            <Text fontSize="b3">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookAndroid}
                    alt={`Download ${config.appTitle} app on your android devices`}
                  />
                </Box>
                <Text fontSize="c2">Android</Text>
              </Stack>
              <Stack gap="2">
                <Box height="16" width="16">
                  <img
                    src={CashBookIos}
                    alt={`Download ${config.appTitle} app on your ios devices`}
                  />
                </Box>
                <Text fontSize="c2">iOS</Text>
              </Stack>
            </Inline>
          </Stack>
        </Inline>
      </Box>
    </Box>
  )
}

import { useNavigate, useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  AddMoneyIcon,
  Alert,
  ArrowRightIcon,
  BankGradientIcon,
  BankPlaceholderIcon,
  Box,
  Button,
  CancelIcon,
  CardDeactivatedIcon,
  CardPendingIcon,
  CBButton,
  CheckIcon,
  ControlExpensesIcon,
  CopyIcon,
  CopyToClipboard,
  DataLoadingFallback,
  Inline,
  PageMeta,
  SearchSelect,
  SkeletonRows,
  SkeletonTitle,
  SpinnerIcon,
  Stack,
  Text,
  Time,
  Tooltip,
  TransactionDate,
  TSelectableBaseOption,
  WhatsAppIcon,
} from "@cashbook/web-components"
import {
  PaymentsTransaction,
  WalletStatusFilterTypes,
  transformDate,
  useWallet,
} from "@cashbook/data-store/payments"
import {
  Avatar,
  BreadCrumbsForPayments,
  CopyTransactionsToCashbook,
  DateSelectForPayments,
  GeneralErrorHandler,
  PaymentsIcons,
  TransactionDetailsInModal,
  getHelperIconForStatus,
  getStatusTitle,
  getTextColorForStatus,
  getTransactionModeOfPayment,
  walletLimits,
} from "../Payments"
import { WhatsAppContactLink } from "../HelpDocs"
import config from "../config"
import { toast } from "react-hot-toast"
import { Amount } from "../support/Intl"
import {
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react"
import {
  TBusinessUser,
  getBusinessRoleDetailsForMember,
} from "@cashbook/data-store/businesses"
import {
  categoryListStore,
  categorySettingsStore,
  userWalletStore,
} from "@cashbook/data-store/storage"
import { NotInWeb } from "../support"
import classNames from "classnames"
import { pluralize, useMount } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { Checkbox } from "../common"
import ChooseCategoryInModal from "../Payments/PaymentFields"

export default function StaffWalletPage() {
  const { staffId, businessId } = useParams()
  if (!staffId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={
          <DataLoadingFallback label="Loading staff wallet details..." />
        }
        traceId="loading_staff_wallets_details"
      >
        <StaffWallet key={staffId} staffId={staffId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const walletHeaderTabs: Array<{
  id: "B2C" | "EXPENSES" | "COLLECTIONS"
  title: string
}> = [
  { id: "EXPENSES", title: "Expenses" },
  { id: "COLLECTIONS", title: "Collections" },
  { id: "B2C", title: "Added to Wallet" },
]

function StaffWallet({
  businessId,
  staffId,
}: {
  businessId: string
  staffId: string
}) {
  const navigate = useNavigate()
  const {
    user,
    error,
    status,
    params,
    lastPage,
    business,
    authUser,
    currentPage,
    userProfile,
    transactions,
    totalExpenses,
    totalCollections,

    areFiltersApplied,
    transactionsCount,
    fetchingTransactions,
    fetchingMoreTransactions,

    fetchMore,
    refreshPage,
    handleDateChange,
    handleParamsChange,
    handleCategoryChange,
    getTeamMemberInfoForId,
    getWalletBalanceForDeactivatedWallet,
    resetFilters,
    fetchPartiesForFilter,
  } = useWallet(businessId, staffId)
  const isWalletPaused = Boolean(
    getTeamMemberInfoForId(user.uid)?.wallet?.paused_at
  )
  const isLoggedInUser = user?.uid === authUser?.uid

  const showWalletSettingsOptions = useMemo(() => {
    const userRole = authUser?.role?.id
    const walletHolderRole = getBusinessRoleDetailsForMember(business, user.uid)

    if (userRole === "owner") {
      return true
    }

    if (userRole === "partner") {
      if (walletHolderRole.id === "staff") {
        return true
      }
      if (walletHolderRole.id === "partner") {
        if (isLoggedInUser) {
          if (!isWalletPaused) {
            return true
          } else {
            const isWalletSelfPaused =
              getTeamMemberInfoForId(user.uid)?.wallet?.paused_by ===
              authUser?.uid
            if (isWalletSelfPaused) {
              return true
            }
          }
        }
      }
    }

    return false
  }, [
    authUser?.role?.id,
    business,
    user?.uid,
    isLoggedInUser,
    authUser?.uid,
    getTeamMemberInfoForId,
    isWalletPaused,
  ])

  const userWallet = userWalletStore.getUserWallet()

  const [deactivatedWalletBalance, setDeactivatedWalletBalance] =
    useState<number>(0)

  const showLimitsAndCardSetting: boolean = useMemo(() => {
    if (user.kycStatus === "full_kyc") {
      if (authUser.uid === user.uid) {
        return true
      }
      const role = getBusinessRoleDetailsForMember(business, user.uid)
      if (role.id === "staff" && authUser.role.id === "owner") {
        return true
      }
      if (authUser.role.id === "owner") {
        return true
      }
    }
    return false
  }, [user.kycStatus, user.uid, authUser.uid, authUser.role.id, business])

  const { from_datetime, to_datetime } = params

  const inviteText =
    userWallet.kycStatus === "init"
      ? `I have created wallet for you to make business expense for ${business.name}. Here is the link to activate wallet`
      : "Your wallet activation is pending due to incomplete KYC. To start making business expenses, please complete your Video KYC. Once your Video KYC is completed, your wallet will be activated and you can start making business expenses hassle-free."

  const fetchDeactivatedWalletBalance = useCallback(
    async (userId: string) => {
      const balance = await getWalletBalanceForDeactivatedWallet(userId)
      setDeactivatedWalletBalance(balance || 0)
    },
    [getWalletBalanceForDeactivatedWallet]
  )

  useMount(() => {
    if (userWallet.deactivation && userWallet.walletId) {
      fetchDeactivatedWalletBalance(userWallet.walletId)
    }
  })

  return (
    <>
      <PageMeta>
        <title>
          {" "}
          {user?.name} (Wallet) - {business.name}
        </title>
      </PageMeta>
      <Box key={businessId} height="full">
        <BreadCrumbsForPayments
          position="relative"
          title={
            isLoggedInUser
              ? "Your Wallet"
              : `${userProfile?.name || user?.name}'s Wallet`
          }
          removeBorderLine
          links={[
            { to: "payments", title: "Payments" },
            { to: "staff-wallets", title: "Member Wallets" },
            {
              to: staffId,
              title: isLoggedInUser
                ? "Your Wallet"
                : userProfile?.name || user?.name || "Staff Wallet Details",
            },
          ]}
        />
        <Box paddingX="8">
          {user && (user?.kycStatus !== "full_kyc" || user.deactivation) ? (
            <Stack gap="10">
              <Box
                maxWidth="3xl"
                rounded="md"
                borderWidth="1"
                borderColor="borderOutline"
              >
                <Inline
                  paddingY="4"
                  paddingX="5"
                  alignItems="center"
                  justifyContent="between"
                  borderBottomWidth="1"
                  borderColor="borderOutline"
                  backgroundColor="surfaceWarningLowest"
                >
                  <Inline alignItems="center" gap="4">
                    {userWallet?.deactivation === "completed" ? (
                      <CardDeactivatedIcon color="iconWarning" />
                    ) : (
                      <CardPendingIcon color="iconWarning" />
                    )}
                    <Stack gap="2">
                      <Text fontSize="s3" color="textWarning">
                        {userWallet?.deactivation === "init"
                          ? "Wallet Deactivation Pending"
                          : userWallet?.deactivation === "completed"
                          ? "Wallet Deactivated"
                          : "Wallet activation pending!"}
                      </Text>
                      <Text fontSize="b3">
                        {userWallet?.deactivation
                          ? `As ${
                              user.name?.split(" ")[0]
                            } is no longer a part of this business`
                          : `Ask ${
                              user.name?.split(" ")[0]
                            } to complete KYC to activate wallet`}
                      </Text>
                    </Stack>
                  </Inline>
                </Inline>
                {userWallet?.deactivation ? (
                  <Stack gap="4" padding="6">
                    <Text fontSize="c2" color="textMedium">
                      Current Wallet Balance
                    </Text>
                    <Amount
                      currency="inr"
                      fontSize="h4"
                      amount={deactivatedWalletBalance}
                    />
                  </Stack>
                ) : (
                  <Inline padding="6" gap="6" alignItems="center">
                    <Box
                      borderWidth="1"
                      borderColor="borderOutline"
                      rounded="md"
                    >
                      <Stack gap="2" padding="6">
                        <Text fontSize="s1">
                          <span role="img" aria-label="greeting">
                            👋🏼
                          </span>{" "}
                          Hey {user?.name?.split(" ")[0]},
                        </Text>
                        <Text
                          className="line-clamp-3"
                          fontSize="c2"
                          color="textMedium"
                        >
                          {inviteText + " - " + config.appDownloadLink}
                        </Text>
                      </Stack>
                      <CopyToClipboard>
                        {({ copied, copy }) => (
                          <Inline
                            gap="2"
                            as="button"
                            paddingY="3"
                            paddingX="6"
                            width="full"
                            color="textPrimary"
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                              toast.success("Link copied successfully.")
                              copy(inviteText + " - " + config.appDownloadLink)
                            }}
                            backgroundColor="surfacePrimaryLowest"
                          >
                            {copied ? (
                              <CheckIcon color="iconSuccess" />
                            ) : (
                              <CopyIcon />
                            )}
                            <Text fontSize="bt">
                              Copy to share with {user?.name}
                            </Text>
                          </Inline>
                        )}
                      </CopyToClipboard>
                    </Box>
                    <Text fontSize="b4">OR</Text>
                    <WhatsAppContactLink
                      fullWidth
                      size="lg"
                      level="primary"
                      phoneNumber={user.phoneNumber}
                      text={inviteText + " - " + config.appDownloadLink}
                    >
                      <WhatsAppIcon />
                      Share Activation Link
                    </WhatsAppContactLink>
                  </Inline>
                )}
              </Box>
              {userWallet?.deactivation ? (
                <TransactionsTable
                  status={params.status}
                  party={params.party}
                  attachments={params.attachments}
                  category={params.category}
                  totalExpenses={totalExpenses}
                  totalCollections={totalCollections}
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  showFetchMore={currentPage !== lastPage}
                  transactions={transactions}
                  transactionsCount={transactionsCount}
                  areFiltersApplied={areFiltersApplied}
                  fetchingTransactions={fetchingTransactions}
                  fetchingMoreTransactions={fetchingMoreTransactions}
                  userIds={{
                    userId: user.uid,
                    authUserId: authUser.uid,
                  }}
                  fetchMore={fetchMore}
                  handleDateChange={handleDateChange}
                  handleParamsChange={handleParamsChange}
                  handleChange={handleCategoryChange}
                  getBusinessUser={getTeamMemberInfoForId}
                  resetFilters={resetFilters}
                  fetchPartiesForFilter={fetchPartiesForFilter}
                  refreshPage={refreshPage}
                  categories={params.categories}
                />
              ) : null}
            </Stack>
          ) : status === "in_progress" ? (
            <Stack gap="8">
              <Stack
                borderWidth="1"
                padding="8"
                rounded="md"
                maxWidth="2xl"
                gap="3"
                borderColor="borderOutline"
              >
                <SkeletonTitle />
                <SkeletonTitle width="full" />
                <SkeletonTitle width="full" />
              </Stack>
              <Inline gap="6" maxWidth="2xl">
                <Stack
                  borderWidth="1"
                  padding="8"
                  rounded="md"
                  width="full"
                  gap="3"
                  borderColor="borderOutline"
                >
                  <SkeletonTitle />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
                <Stack
                  borderWidth="1"
                  padding="8"
                  rounded="md"
                  width="full"
                  gap="3"
                  borderColor="borderOutline"
                >
                  <SkeletonTitle />
                  <SkeletonTitle width="full" />
                  <SkeletonTitle width="full" />
                </Stack>
              </Inline>
              <Box as="table">
                <SkeletonRows numOfRows={10} numOfCols={6} />
              </Box>
            </Stack>
          ) : error ? (
            <Box width="full" paddingY="24">
              <GeneralErrorHandler onRetry={refreshPage} />
            </Box>
          ) : userProfile ? (
            <Stack gap="10">
              <Inline gap="8">
                <Stack maxWidth="2xl" width="full" gap="6">
                  <Box borderWidth="1" rounded="md" borderColor="borderOutline">
                    <Inline
                      paddingY="4"
                      paddingX="6"
                      alignItems="center"
                      justifyContent="between"
                      gap="2"
                      borderBottomWidth="1"
                      borderColor="borderOutline"
                      backgroundColor="surfaceNeutralLowest"
                    >
                      <Inline alignItems="center" gap="4">
                        <Avatar
                          size="8"
                          fontSize="b1"
                          id={userProfile.uid}
                          name={userProfile.name || user.name}
                        />
                        <Text fontSize="s3" className="line-clamp-1">
                          {userProfile.name || user.name}
                          {isLoggedInUser ? " (You) " : null}
                        </Text>
                      </Inline>
                    </Inline>
                    {isWalletPaused ? (
                      <Alert
                        status="warning"
                        marginBottom="0"
                        marginTop="6"
                        marginX="6"
                        borderWidth="0"
                      >
                        <Text fontSize="c2">This wallet is paused!</Text>
                      </Alert>
                    ) : null}
                    <Inline
                      padding="6"
                      alignItems="center"
                      justifyContent="between"
                      gap="2"
                    >
                      <Stack gap="4">
                        <Text fontSize="c2" color="textMedium">
                          Current Wallet Balance
                        </Text>
                        <Stack gap="2">
                          <Amount
                            currency="inr"
                            fontSize="h4"
                            amount={userProfile.balance || 0}
                          />
                          <Inline gap="1" alignItems="center">
                            <Text fontSize="c2" color="textMedium">
                              Monthly Limit:{" "}
                            </Text>
                            <Amount
                              currency="inr"
                              fontSize="c2"
                              color="textMedium"
                              amount={walletLimits[user.kycStatus]}
                            />
                          </Inline>
                        </Stack>
                      </Stack>
                      {!isWalletPaused ? (
                        <NotInWeb heading="Use mobile app to use this feature">
                          {({ onDisplay }) => (
                            <Button
                              onClick={() => {
                                onDisplay()
                                trackEvent(TrackingEvents.ADD_MONEY_CLICKED, {
                                  from:
                                    user.uid !== userProfile.uid
                                      ? "memberWalletScreen"
                                      : "yourWalletScreen",
                                })
                              }}
                              status="success"
                              size="lg"
                            >
                              <AddMoneyIcon />
                              Add Money
                            </Button>
                          )}
                        </NotInWeb>
                      ) : null}
                    </Inline>
                  </Box>
                  {showLimitsAndCardSetting ? (
                    <NotInWeb heading="Use mobile app to use this feature">
                      {({ onDisplay }) => (
                        <Inline gap="6">
                          <Inline
                            alignItems="center"
                            rounded="md"
                            borderWidth="1"
                            borderColor="borderOutline"
                            width="full"
                            padding="4"
                            justifyContent="between"
                            onClick={() => {
                              if (showWalletSettingsOptions) {
                                navigate(
                                  `/businesses/${businessId}/payments/staff-wallets/${staffId}/wallet-settings?header=none`
                                )
                              } else {
                                trackEvent(
                                  TrackingEvents.CHANGE_WALLET_LIMITS_CLICKED
                                )
                                onDisplay()
                              }
                            }}
                          >
                            <Inline gap="4" alignItems="center">
                              <ControlExpensesIcon size="10" />
                              <Stack gap="2">
                                <Text fontSize="s4">
                                  {showWalletSettingsOptions
                                    ? "Wallet Limits & Settings"
                                    : "Change Wallet Limits"}
                                </Text>
                                <Text fontSize="c2" color="textMedium">
                                  {showWalletSettingsOptions
                                    ? "Per transaction, Daily transactions, Pause/Unpause wallet"
                                    : "Per transaction, Daily transactions"}
                                </Text>
                              </Stack>
                            </Inline>
                            <ArrowRightIcon />
                          </Inline>
                        </Inline>
                      )}
                    </NotInWeb>
                  ) : null}
                </Stack>
              </Inline>
              <Stack paddingTop="6">
                <TransactionsTable
                  status={params.status}
                  party={params.party}
                  attachments={params.attachments}
                  category={params.category}
                  totalExpenses={totalExpenses}
                  totalCollections={totalCollections}
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  showFetchMore={currentPage !== lastPage}
                  transactions={transactions}
                  transactionsCount={transactionsCount}
                  areFiltersApplied={areFiltersApplied}
                  fetchingTransactions={fetchingTransactions}
                  fetchingMoreTransactions={fetchingMoreTransactions}
                  userIds={{
                    userId: user.uid,
                    authUserId: authUser.uid,
                  }}
                  fetchMore={fetchMore}
                  handleDateChange={handleDateChange}
                  handleParamsChange={handleParamsChange}
                  handleChange={handleCategoryChange}
                  getBusinessUser={getTeamMemberInfoForId}
                  resetFilters={resetFilters}
                  fetchPartiesForFilter={fetchPartiesForFilter}
                  refreshPage={refreshPage}
                  categories={params.categories}
                />
              </Stack>
            </Stack>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

const MAX_CAN_COPY = 100

const walletTransactionStatusFilter: {
  id: WalletStatusFilterTypes
  label: string
}[] = [
  { id: "ALL", label: "All" },
  { id: "SUCCESS", label: "Success" },
  { id: "PENDING", label: "Pending" },
  { id: "REFUNDED", label: "Refunded" },
  { id: "FAILURE", label: "Failed" },
]

const PAYMENT_ATTACHMENTS = [
  {
    id: "attached",
    label: "Bill Attached",
  },
  {
    id: "missing",
    label: "Bill Missing",
  },
]

function TransactionsTable({
  status,
  party,
  attachments,
  userIds,
  category,
  to_datetime,
  transactions,
  from_datetime,
  totalExpenses,
  totalCollections,
  showFetchMore,
  areFiltersApplied,
  transactionsCount,
  fetchingTransactions,
  fetchingMoreTransactions,
  fetchMore,
  handleChange,
  getBusinessUser,
  handleDateChange,
  handleParamsChange,
  resetFilters,
  fetchPartiesForFilter,
  refreshPage,
  categories,
}: {
  status?: WalletStatusFilterTypes
  party?: { id: string; name: string; address: string } | undefined
  attachments?:
    | {
        id: "attached" | "missing"
        label: string
      }
    | undefined
  to_datetime?: Date
  from_datetime?: Date
  totalExpenses?: number
  totalCollections?: number
  showFetchMore: boolean
  transactions: PaymentsTransaction[]
  category?: "B2C" | "EXPENSES" | "COLLECTIONS"
  transactionsCount?: number
  areFiltersApplied: boolean
  fetchingTransactions?: boolean
  fetchingMoreTransactions?: boolean
  userIds: {
    userId?: string
    authUserId?: string
  }
  fetchMore: () => void
  handleDateChange: (date1?: Date, date2?: Date, label?: string) => void
  handleChange: (optionId: "B2C" | "EXPENSES" | "COLLECTIONS") => void
  handleParamsChange: (key: string, value?: unknown) => void
  getBusinessUser?: (id: string) => TBusinessUser | null
  resetFilters: () => void
  fetchPartiesForFilter: (q: string) => Promise<
    {
      id: string
      name: string
      label: string
      address: string
    }[]
  >
  refreshPage: (changeTab: boolean) => void
  categories?: { id: string; label: string }
}) {
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([])

  const data = useParams()

  const npciTransactionIds = useMemo(() => {
    const npciTransactionIds: string[] = []
    selectedTransactions.forEach((transactionId) => {
      const transactionItem = transactions?.find(
        (transaction) => transaction.id === transactionId
      )
      if (transactionItem?.npci_txn_id) {
        npciTransactionIds.push(transactionItem?.npci_txn_id)
      }
    })
    return npciTransactionIds
  }, [selectedTransactions, transactions])

  const categoriesListMappedWithBusinessId = useSyncExternalStore(
    categoryListStore.subscribe,
    categoryListStore.getCategorySettings
  )

  const categoriesList =
    categoriesListMappedWithBusinessId[data.businessId || ""]

  const categorySettingsMappedWithBusinessId = useSyncExternalStore(
    categorySettingsStore.subscribe,
    categorySettingsStore.getCategorySettings
  )

  const isCategoryDisabled =
    categorySettingsMappedWithBusinessId[data.businessId || ""]?.disabled

  const categoriesForFilter: TSelectableBaseOption[] = useMemo(() => {
    const categoryListForFilter: TSelectableBaseOption[] = []
    categoriesList &&
      categoriesList.forEach((category) => {
        if (category.id) {
          categoryListForFilter.push({
            id: category.id,
            label: category.name,
          })
        }
      })
    return categoryListForFilter
  }, [categoriesList])

  const statusLabel: string = useMemo(() => {
    switch (status) {
      case "REJECTED":
        return "Declined"
      case "EXPIRED":
        return "Expired"
      case "FAILURE":
        return "Failed"
      case "PENDING":
        return "Pending"
      case "SUCCESS":
        return "Success"
      case "REFUNDED":
        return "Refunded"
      default:
        return "Status: All"
    }
  }, [status])
  return (
    <Stack gap="6">
      <Inline as="ul" gap="6" borderBottomWidth="1" borderColor="borderOutline">
        {walletHeaderTabs.map((option) => (
          <Box as="li" key={option.id}>
            <Box
              as="button"
              onClick={() => {
                if (option.id !== category) {
                  setSelectedTransactions([])
                  handleChange(option.id)
                }
              }}
              padding="3"
              roundedTop="md"
              color={category === option.id ? "textPrimary" : "textMedium"}
              borderBottomWidth={category === option.id ? "2" : "0"}
              borderColor="borderPrimary"
              backgroundColor={
                category === option.id ? "surfacePrimaryLowest" : "transparent"
              }
              className={classNames({
                "hover:bg-blue-5": category !== option.id,
              })}
            >
              <Text fontSize="s4">{option.title}</Text>
            </Box>
          </Box>
        ))}
      </Inline>
      <Stack gap="6">
        {category === "EXPENSES" || category === "COLLECTIONS" ? (
          <Stack gap="6">
            <Inline gap="4" alignItems="center">
              <Box>
                <DateSelectForPayments
                  to_datetime={to_datetime}
                  from_datetime={from_datetime}
                  onChange={(from, to, label) => {
                    handleDateChange(from, to, label)
                  }}
                />
              </Box>
              <Box>
                <SearchSelect
                  searchDisabled
                  hasValue={Boolean(status)}
                  onChange={(option) => {
                    handleParamsChange(
                      "status",
                      option && option.id !== "ALL" ? option.id : undefined
                    )
                  }}
                  options={walletTransactionStatusFilter}
                  value={status ? status : "ALL"}
                  label={statusLabel}
                />
              </Box>
              <Box>
                <SearchSelect
                  label={
                    !categories ? "Payment Category" : `${categories.label}`
                  }
                  searchPlaceholder="Search Categories"
                  onChange={(option) => {
                    if (option?.id !== categories?.id) {
                      handleParamsChange(
                        "categories",
                        option
                          ? {
                              id: option.id,
                              label: option.label,
                            }
                          : undefined
                      )
                    }
                  }}
                  options={categoriesForFilter}
                  value={categories ? categories.id : null}
                />
              </Box>
              <Box>
                <SearchSelect
                  label={!party ? "Parties: All" : `${party?.name || ""}`}
                  searchPlaceholder="Search Parties"
                  onChange={(option) => {
                    handleParamsChange(
                      "party",
                      option
                        ? {
                            id: option.id,
                            name: option.name,
                            address: option.address,
                          }
                        : undefined
                    )
                  }}
                  fetchOptions={(q: string) => fetchPartiesForFilter(q)}
                  value={party ? party.address : null}
                />
              </Box>
              <Box>
                <SearchSelect
                  searchDisabled
                  hasValue={Boolean(attachments?.id)}
                  label={
                    !attachments ? "Bill: All" : `${attachments.label || ""}`
                  }
                  onChange={(option) => {
                    handleParamsChange(
                      "attachments",
                      option ? option : undefined
                    )
                  }}
                  options={PAYMENT_ATTACHMENTS}
                  value={attachments ? attachments : null}
                />
              </Box>
              {areFiltersApplied ? (
                <Box>
                  <Button level="tertiary" inline onClick={resetFilters}>
                    <CancelIcon />
                    Clear All
                  </Button>
                </Box>
              ) : null}
            </Inline>
            <Stack
              rounded="md"
              borderWidth="1"
              maxWidth="2xl"
              padding="6"
              gap="2"
              borderColor="borderOutline"
            >
              <Text fontSize="c2" color="textMedium">
                {category === "COLLECTIONS"
                  ? "Total Collections"
                  : "Total Expenses"}
              </Text>
              <Amount
                currency="inr"
                amount={
                  category === "COLLECTIONS"
                    ? totalCollections || 0
                    : totalExpenses || 0
                }
                color={
                  category === "COLLECTIONS" ? "textCashIn" : "textCashOut"
                }
                fontSize="s1"
              />
            </Stack>
          </Stack>
        ) : null}
        <Stack gap="4" paddingBottom="12">
          {transactions.length ? (
            <Text fontSize="c2" color="textMedium">
              1-{transactions.length} of {transactionsCount}{" "}
              {pluralize("transaction", transactionsCount)}
            </Text>
          ) : null}
          {category === "EXPENSES" || category === "COLLECTIONS" ? (
            <>
              {fetchingTransactions ? (
                <Box as="table" width="full" position="relative">
                  <SkeletonRows numOfRows={4} numOfCols={6} />
                </Box>
              ) : transactions.length ? (
                <>
                  <Box as="table" width="full" position="relative">
                    <Box as="thead">
                      <tr>
                        <Box
                          className="w-[24px] z-[1] "
                          as="th"
                          position="sticky"
                          bgColor="backgroundLight3"
                          paddingX="3"
                          paddingY="4"
                          top="0"
                        />
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          bgColor="backgroundLight3"
                          className={`w-[130px]`}
                        >
                          <Text fontSize="s4">Date & Time</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="z-[1] w-[152px]"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="end" paddingX="2">
                            <Text fontSize="s4">Transaction ID</Text>
                          </Inline>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="w-[40px]"
                          bgColor="backgroundLight3"
                        >
                          <Text fontSize="s4">Mode</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="max-[152px]"
                          bgColor="backgroundLight3"
                        >
                          <Text fontSize="s4">Party</Text>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="end">
                            <Text fontSize="s4">Amount</Text>
                          </Inline>
                        </Box>
                        <Box
                          as="th"
                          position="sticky"
                          top="0"
                          paddingX="3"
                          paddingY="4"
                          className="z-[1]"
                          bgColor="backgroundLight3"
                        >
                          <Inline justifyContent="center">
                            <Text fontSize="s4">Bill</Text>
                          </Inline>
                        </Box>
                      </tr>
                    </Box>
                    <Box as="tbody">
                      {transactions.map((transaction) => (
                        <TransactionItem
                          key={transaction.id}
                          transaction={transaction}
                          isSelected={selectedTransactions.includes(
                            transaction.id
                          )}
                          onTransactionSelect={(tId) => {
                            if (selectedTransactions.includes(tId)) {
                              setSelectedTransactions((prev) =>
                                prev.filter((t) => t !== tId)
                              )
                              return
                            }
                            if (
                              transaction.status === "SUCCESS" ||
                              transaction.status === "REFUNDED"
                            ) {
                              if (
                                selectedTransactions.length > MAX_CAN_COPY ||
                                selectedTransactions.length + 1 > MAX_CAN_COPY
                              ) {
                                toast.error(
                                  `Cannot select more than ${MAX_CAN_COPY} transactions`
                                )
                                setSelectedTransactions((prev) =>
                                  prev.splice(0, MAX_CAN_COPY)
                                )
                                return
                              }
                              setSelectedTransactions((prev) => [...prev, tId])
                            } else {
                              toast.error(`This transaction can't be copied`)
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                  {showFetchMore ? (
                    <Box>
                      <Button
                        level="primary"
                        onClick={fetchMore}
                        disabled={fetchingMoreTransactions}
                      >
                        <Text fontSize="bt">Show More Transactions</Text>
                        {fetchingMoreTransactions && <SpinnerIcon />}
                      </Button>
                    </Box>
                  ) : null}
                </>
              ) : (
                <Stack gap="8" className="py-32">
                  <Stack
                    height="full"
                    alignItems="center"
                    justifyContent="center"
                    gap="2"
                  >
                    <Text fontSize="s1">
                      {category === "COLLECTIONS"
                        ? "No collections found"
                        : "No expenses found"}
                    </Text>
                    <Text fontSize="b3" color="textMedium">
                      {areFiltersApplied
                        ? "No transactions for selected filters."
                        : "No transactions has been made"}
                    </Text>
                  </Stack>
                  {areFiltersApplied ? (
                    <Box alignSelf="center">
                      <Button
                        level="primary"
                        onClick={() => {
                          handleChange("EXPENSES")
                        }}
                      >
                        Reset Filters
                      </Button>
                    </Box>
                  ) : null}
                </Stack>
              )}
            </>
          ) : category === "B2C" ? (
            <>
              {fetchingTransactions ? (
                <Box as="table">
                  <SkeletonRows numOfRows={6} numOfCols={5} />
                </Box>
              ) : transactions.length ? (
                <Box as="table" width="full" position="relative">
                  <Box as="thead" className="whitespace-pre">
                    <tr>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        bgColor="backgroundLight3"
                        className={`w-[130px]`}
                      >
                        <Text fontSize="s4">Date & Time</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">Transaction by</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">From</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className="min-w-[150px]"
                        bgColor="backgroundLight3"
                      >
                        <Text fontSize="s4">To</Text>
                      </Box>
                      <Box
                        as="th"
                        position="sticky"
                        top="0"
                        paddingX="3"
                        paddingY="4"
                        className={`w-[150px]`}
                        bgColor="backgroundLight3"
                      >
                        <Inline justifyContent="end">
                          <Text fontSize="s4">Amount</Text>
                        </Inline>
                      </Box>
                    </tr>
                  </Box>
                  <Box as="tbody">
                    {transactions.map((transaction, i) => (
                      <InternalTransactionItem
                        key={transaction.id}
                        transaction={transaction}
                        authUserId={userIds.authUserId || ""}
                        isLastTransaction={i === transactions.length - 1}
                        memberId={userIds.userId}
                        getBusinessUser={getBusinessUser}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <Stack
                  gap="2"
                  paddingY="24"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="s1">No transactions found</Text>
                  <Text fontSize="b3" color="textMedium">
                    No deposits have been made to this wallet
                  </Text>
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      </Stack>
      {selectedTransactions.length ? (
        <Inline
          position="sticky"
          backgroundColor="white"
          bottom="0"
          width="full"
          paddingY="6"
          borderTopWidth="1"
          borderColor="borderDividers"
          alignItems="center"
          justifyContent="start"
        >
          <CBButton
            level="secondary"
            size="lg"
            iconPlacement="left"
            onClick={() => {
              setSelectedTransactions([])
            }}
          >
            Unselect All
          </CBButton>
          <Inline marginLeft="4">
            <Text fontSize="b2">
              <Text as="span" fontSize="b1">
                {selectedTransactions.length}{" "}
                {pluralize("transaction", selectedTransactions.length)}
              </Text>{" "}
              selected
            </Text>
          </Inline>
          <Inline gap="6" justifyContent="end" alignItems="center" flex="1">
            {!isCategoryDisabled ? (
              <ChooseCategoryInModal
                source="walletTransactions"
                businessId={data.businessId || ""}
                npciTranscationIds={npciTransactionIds}
                onSuccess={() => {
                  refreshPage(false)
                }}
              >
                {({ handleAction }) => (
                  <Box cursor="pointer">
                    <CBButton
                      type="button"
                      onClick={() => handleAction("copy")}
                      size="lg"
                    >
                      Add Category
                    </CBButton>
                  </Box>
                )}
              </ChooseCategoryInModal>
            ) : null}
            <CopyTransactionsToCashbook
              businessId={data.businessId || ""}
              transactions={
                transactions?.length
                  ? transactions?.filter((t) =>
                      selectedTransactions.includes(t.id)
                    )
                  : []
              }
            >
              {({ open }) => (
                <CBButton
                  level="primary"
                  size="lg"
                  iconPlacement="left"
                  onClick={() => {
                    open()
                    trackEvent(
                      TrackingEvents.PAYMENTS_COPY_TO_CASHBOOK_INITIATED,
                      { from: "multiSelect", screen: "walletTransactions" }
                    )
                  }}
                >
                  <CopyIcon />
                  Copy To Cashbook
                </CBButton>
              )}
            </CopyTransactionsToCashbook>
          </Inline>
        </Inline>
      ) : null}
    </Stack>
  )
}

function TransactionItem({
  transaction,
  isSelected,
  onTransactionSelect,
}: {
  transaction: PaymentsTransaction
  isSelected: boolean
  onTransactionSelect: (tId: string) => void
}) {
  const {
    timestamp,
    party,
    created_at,
    id,
    type,
    amount,
    status,
    attachments,
    transaction_type,
    business_id,
    npci_txn_id,
  } = transaction

  const [numOfAttachments, setNumOfAttachmentIds] = useState<number>(
    attachments?.length || 0
  )

  const transactionId = npci_txn_id

  const transactionType = useMemo(() => {
    return getTransactionModeOfPayment(transaction_type)
  }, [transaction_type])

  const partyInvolved: {
    id: string
    name: string
    type: "individual" | "merchant"
  } = useMemo(() => {
    return {
      id: party?.id || id,
      name: party?.name || "",
      type: party?.entity_type === "ENTITY" ? "merchant" : "individual",
    }
  }, [id, party?.entity_type, party?.id, party?.name])

  function onAttachmentAdded() {
    setNumOfAttachmentIds((prevCount) => {
      return prevCount + 1
    })
  }

  function onAttachmentDeleted() {
    setNumOfAttachmentIds((prevCount) => {
      if (prevCount > 0) return prevCount - 1
      return 0
    })
  }

  return (
    <TransactionDetailsInModal
      transactionId={id}
      npciTransactionId={npci_txn_id}
      isBillAttached={numOfAttachments > 0}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
      onAttachmentAdded={onAttachmentAdded}
      onAttachmentDeleted={onAttachmentDeleted}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth="1"
          className={classNames("group z-0", {
            "bg-[#B6C1EE]": isSelected,
            "hover:bg-gray-100 ": !isSelected,
          })}
          cursor="pointer"
          onClick={open}
        >
          <Box
            className="w-[24px] z-[1]"
            as="th"
            paddingX="3"
            paddingY="4"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation()
              onTransactionSelect?.(id)
            }}
          >
            <Inline gap="2" justifyContent="center" alignItems="center">
              <Checkbox size="4" isSelected={isSelected} />
            </Inline>
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
            <Stack gap="2">
              <TransactionDate
                fontSize="b3"
                timeStamp={transformDate(timestamp || created_at)}
              />
              <Time
                fontSize="c2"
                color="textMedium"
                timeStamp={transformDate(timestamp || created_at)}
                format="hh:mm a"
              />
              {status !== "SUCCESS" && status !== "REFUNDED" ? (
                <Inline
                  gap="1"
                  alignItems="center"
                  color={getTextColorForStatus({ status })}
                >
                  {getHelperIconForStatus({ status, size: "4" })}
                  <Text fontSize="c2">{getStatusTitle({ status, type })}</Text>
                </Inline>
              ) : null}
            </Stack>
          </Box>
          {transactionId ? (
            <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
              <Inline gap="2" zIndex="0" justifyContent="end" paddingX="2">
                <Tooltip event="onHover" content={transactionId}>
                  <Text
                    color="textHigh"
                    fontSize="b3"
                    className="line-clamp-1 break-all"
                  >
                    ...
                    {transactionId.substring(
                      transactionId.length - 10,
                      transactionId.length
                    ) || "N/A"}
                  </Text>
                </Tooltip>
                <CopyToClipboard>
                  {({ copy, copied }) => (
                    <Box
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        copy(transactionId)
                        toast.success("Transaction ID copied to clipboard")
                      }}
                    >
                      <Tooltip event="onHover" content={"Copy to Clipboard"}>
                        {copied ? (
                          <CheckIcon size="4" color="iconSuccess" />
                        ) : (
                          <CopyIcon size="4" color="iconMedium" />
                        )}
                      </Tooltip>
                    </Box>
                  )}
                </CopyToClipboard>
              </Inline>
            </Box>
          ) : null}
          <Box as="td" paddingX="3" paddingY="4" className=" whitespace-pre">
            <PaymentsIcons size="4" color="iconMedium" name={transactionType} />
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="max-w-[152px]">
            {partyInvolved.name.length ? (
              <Inline alignItems="center" gap="2">
                <Avatar
                  id={partyInvolved.id}
                  name={partyInvolved.name}
                  size="6"
                  fontSize="c2"
                  type={partyInvolved.type}
                />
                <Text fontSize="b3" className="line-clamp-1">
                  {partyInvolved.name}
                </Text>
              </Inline>
            ) : null}
          </Box>
          <Box as="td" paddingX="2" paddingY="5">
            <Inline justifyContent="end" paddingX="2">
              <Amount
                type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                amount={Number(amount)}
                currency="inr"
                fontWeight="semibold"
              />
            </Inline>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="5"
            className="align-middle"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {status === "SUCCESS" ? (
              <>
                {numOfAttachments > 0 ? (
                  <Tooltip event="onHover" content={"Bill Attached"}>
                    <Box size="8">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.7144 4.66669L24.7144 2.66669L22.7144 4.66669L20.7144 2.66669L18.7144 4.66669L16.7144 2.66669L14.7144 4.66669L12.7144 2.66669L10.7144 4.66669L8.71436 2.66669L6.71436 4.66669L4.71436 2.66669V29.3334L6.71436 27.3334L8.71436 29.3334L10.7144 27.3334L12.7144 29.3334L14.7144 27.3334L16.7144 29.3334L18.7144 27.3334L20.7144 29.3334L22.7144 27.3334L24.7144 29.3334L26.7144 27.3334L28.7144 29.3334V2.66669L26.7144 4.66669ZM26.0477 25.4534H7.38102V6.54669H26.0477V25.4534ZM8.71436 20H24.7144V22.6667H8.71436V20ZM8.71436 14.6667H24.7144V17.3334H8.71436V14.6667ZM8.71436 9.33335H24.7144V12H8.71436V9.33335Z"
                          fill="#757575"
                        />
                      </svg>
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip event="onHover" content={"Bill Missing"}>
                    <Box>
                      <svg
                        height="33"
                        width="32"
                        viewBox="0 0 33 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.1428 7.98658L26.1828 25.3332H6.10282L16.1428 7.98658ZM3.79615 23.9999C2.76949 25.7732 4.04949 27.9999 6.10282 27.9999H26.1828C28.2362 27.9999 29.5162 25.7732 28.4895 23.9999L18.4495 6.65324C17.4228 4.87991 14.8628 4.87991 13.8362 6.65324L3.79615 23.9999ZM14.8095 14.6666V17.3332C14.8095 18.0666 15.4095 18.6666 16.1428 18.6666C16.8762 18.6666 17.4762 18.0666 17.4762 17.3332V14.6666C17.4762 13.9332 16.8762 13.3332 16.1428 13.3332C15.4095 13.3332 14.8095 13.9332 14.8095 14.6666ZM14.8095 21.3332H17.4762V23.9999H14.8095V21.3332Z"
                          fill="#BD610D"
                        />
                      </svg>
                    </Box>
                  </Tooltip>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}

type TransactionUser = {
  id: string
  name: string
  icon?: "bank" | "master_wallet"
}
function InternalTransactionItem({
  memberId,
  authUserId,
  transaction,
  isLastTransaction,
  getBusinessUser,
}: {
  authUserId: string
  memberId?: string
  isLastTransaction?: boolean
  transaction: PaymentsTransaction
  getBusinessUser?: (id: string) => TBusinessUser | null
}) {
  const { id, created_by, transaction_type, business_id } = transaction

  const transactionBy = useMemo(() => {
    const bUser = getBusinessUser?.(created_by || "")
    if (authUserId === bUser?.id) {
      return { ...bUser, name: "You" }
    }
    return bUser
  }, [getBusinessUser, created_by, authUserId])

  const userInvolved = useMemo(() => {
    const bUser = getBusinessUser?.(memberId || "")
    if (authUserId === bUser?.id) {
      return { ...bUser, name: "Your Wallet" }
    }
    return bUser
  }, [memberId, getBusinessUser, authUserId])

  const transactionFrom: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
        return { id, name: "Bank Account", icon: "bank" }
      case "C2B":
        return { id: userInvolved?.id || id, name: userInvolved?.name || "" }
      default:
        return { id, name: "Master Wallet", icon: "master_wallet" }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  const transactionTo: TransactionUser = useMemo(() => {
    switch (transaction_type) {
      case "B2B":
      case "VIRTUAL_ACCOUNT_CREDIT":
      case "C2B":
        return { id, name: "Master Wallet", icon: "master_wallet" }
      default:
        return {
          id: userInvolved?.id || id,
          name: userInvolved?.name || "",
        }
    }
  }, [transaction_type, id, userInvolved?.id, userInvolved?.name])

  return (
    <TransactionDetailsInModal
      memberId={memberId}
      transactionId={id || "missing"}
      transaction_type={transaction_type}
      businessId={business_id || "missing"}
    >
      {({ open }) => (
        <Box
          as="tr"
          borderBottomWidth={isLastTransaction ? undefined : "1"}
          borderColor="borderDividers"
          onClick={open}
          className="cursor-pointer hover:bg-gray-100"
        >
          <Box as="td" paddingX="3" paddingY="4" className="whitespace-pre">
            <Stack gap="2">
              <TransactionDate
                fontSize="b3"
                timeStamp={transformDate(transaction.timestamp)}
              />
              <Time
                fontSize="c2"
                color="textMedium"
                timeStamp={transformDate(transaction.timestamp)}
                format="hh:mm a"
              />
            </Stack>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            {transactionBy?.name ? (
              <Inline alignItems="center" gap="2">
                <Box>
                  <Avatar
                    id={transactionBy?.id || "missing"}
                    name={transactionBy?.name}
                    size="6"
                    fontSize="c2"
                  />
                </Box>

                <Text fontSize="b3" className="w-full line-clamp-1">
                  {transactionBy?.name}
                </Text>
              </Inline>
            ) : (
              <Text fontSize="b3">-</Text>
            )}
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionFrom.icon ? (
                  transactionFrom.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionFrom?.id || id}
                    name={transactionFrom?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionFrom?.name}
              </Text>
            </Inline>
          </Box>
          <Box
            as="td"
            paddingX="3"
            paddingY="4"
            className="align-middle max-w-[180px]"
          >
            <Inline alignItems="center" gap="2">
              <Box>
                {transactionTo.icon ? (
                  transactionTo.icon === "bank" ? (
                    <BankPlaceholderIcon color="iconLow" />
                  ) : (
                    <BankGradientIcon />
                  )
                ) : (
                  <Avatar
                    id={transactionTo?.id || id}
                    name={transactionTo?.name}
                    size="6"
                    fontSize="c2"
                  />
                )}
              </Box>
              <Text fontSize="b3" className="w-full line-clamp-1">
                {transactionTo?.name}
              </Text>
            </Inline>
          </Box>
          <Box as="td" paddingX="3" paddingY="4" className="align-middle">
            <Inline justifyContent="end">
              <Amount
                type={transaction.type === "DEBIT" ? "cash-out" : "cash-in"}
                amount={Number(transaction.amount)}
                currency="inr"
                fontWeight="semibold"
              />
            </Inline>
          </Box>
        </Box>
      )}
    </TransactionDetailsInModal>
  )
}
